import React, { useState, useEffect } from "react";
import { Drawer } from "antd";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { addUpdateOffer } from "../../api/offer";
import { getOffer, getOfferDetails } from "../slice";
import { getAllUsers } from "../slice";

const AddOffers = ({ show, handleShow, id, status, search }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.offerDetail);
  const { enqueueSnackbar } = useSnackbar();
  const [viewportWidth, setViewportWidth] = useState("");
  const users = useSelector((state) => state?.allusers);

  useEffect(() => {
    if (typeof window.innerWidth != "undefined") {
      setViewportWidth(window.innerWidth);
    }
  }, []);
  useEffect(() => {
    dispatch(getAllUsers({ status: 1 }));
  }, [dispatch, id]);
  useEffect(() => {
    if (id) {
      dispatch(getOfferDetails({ id }));
    }
  }, [dispatch]);

  const formik = useFormik({
    initialValues: {
      promocode: id ? data?.promocode : "",
      validity: id ? data?.validity?.substr(0, 10) : "",
      discount: id ? data?.discount : "",
      description: id ? data?.description : "",
      user: "",
      notify_all: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      promocode: Yup.string().required("*Required"),
      validity: Yup.string().required("*Required"),
      description: Yup.string().required("*Required"),
      discount: Yup.number()
        .min(0.1, " *Must be greater than or equal to 0.1")
        .max(100, " *Must be less than 100")
        .required("*Required."),
    }),
    onSubmit: (values, { resetForm }) => {
      const { promocode, validity, description, discount, user, notify_all } =
        values;
      const payload = {
        promocode: promocode,
        validity: validity,
        description: description,
        discount: discount,
        notified_users: user ? [user] : [],
        notify_all: notify_all,
      };
      if (id) {
        payload.offerId = id;
      }
      addUpdateOffer(payload).then((response) => {
        // setSaving(false);
        enqueueSnackbar(response?.message);
        if (response?.error_code === 0) {
          resetForm({});
          dispatch(getOffer({}));
          handleShow();
        }
      });
    },
  });

  return (
    <>
      <Drawer
        placement="right"
        onClose={handleShow}
        visible={show}
        width={viewportWidth > 700 ? "40%" : "100%"}
      >
        <div className="px-5 pb-5">
          <div className="text-center">
            <h2 className="offcanvas-title mb-3">
              {id ? "Edit Offer" : "Add Offer"}{" "}
            </h2>
          </div>
          <form className="form-style mt-5" onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                <div className="form-groupp">
                  <label className="text_label">Promo Code</label>
                  <div className="input-containerr">
                    <input
                      type="text"
                      className="form-control add_user_info"
                      {...formik.getFieldProps("promocode")}
                      placeholder="Promo Code"
                    />
                    {/* <img src="images/dollar.png" className="input-img" alt="" /> */}
                  </div>
                  {formik.touched.promocode && formik.errors.promocode ? (
                    <span className="error">{formik.errors.promocode}</span>
                  ) : null}
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                <div className="form-groupp">
                  <label className="text_label">Discount</label>
                  <div className="input-containerr">
                    <input
                      type="number"
                      className="form-control add_user_info"
                      {...formik.getFieldProps("discount")}
                      placeholder="Discount"
                    />
                    {/* <img src="images/dollar.png" className="input-img" alt="" /> */}
                  </div>
                  {formik.touched.discount && formik.errors.discount ? (
                    <span className="error">{formik.errors.discount}</span>
                  ) : null}
                </div>
              </div>

              <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                <div className="form-groupp">
                  <label className="text_label">Validity</label>
                  <input
                    type="date"
                    className="form-control add_user_info"
                    placeholder="Validity Date"
                    {...formik.getFieldProps("validity")}
                  />
                  {/* <img src="images/dollar.png" className="input-img" alt="" /> */}
                </div>
                {formik.touched.validity && formik.errors.validity ? (
                  <span className="error">{formik.errors.validity}</span>
                ) : null}
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 mb-5 mt-4">
                <div className="form-groupp">
                  <div className="nutritionRadio">
                    <label className="text_label">Notify Users:</label>
                    <p className="mb-0">
                      <input
                        type="radio"
                        id="test1"
                        name="radio-group"
                        onChange={() =>
                          formik.setFieldValue("notify_all", "yes")
                        }
                      />
                      <label for="test1">All</label>
                    </p>
                    <p className="mb-0">
                      <input
                        type="radio"
                        id="test2"
                        name="radio-group"
                        onChange={() =>
                          formik.setFieldValue("notify_all", "no")
                        }
                      />
                      <label for="test2">Select</label>
                    </p>
                  </div>
                </div>
              </div>
              {formik.values.notify_all === "no" ? (
                <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                  <label className="text_label">User</label>
                  <select
                    className="form-control add_user_info"
                    {...formik.getFieldProps("user")}
                  >
                    <option value="">Select User</option>
                    {users?.userList?.map((el, i) => {
                      return (
                        <option value={el?._id}>
                          {el?.first_name} {el?.last_name}
                        </option>
                      );
                    })}
                  </select>
                  {formik.touched.user && formik.errors.user ? (
                    <span className="error">{formik.errors.user}</span>
                  ) : null}
                </div>
              ) : (
                ""
              )}
              <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                <label className="text_label">Description</label>
                <div className="form-groupp">
                  <textarea
                    className=" textarea-control"
                    rows={10}
                    {...formik.getFieldProps("description")}
                    placeholder="Description"
                  ></textarea>
                  {/* <img src="images/dollar.png" className="input-img" alt="" /> */}
                </div>
                {formik.touched.description && formik.errors.description ? (
                  <span className="error">{formik.errors.description}</span>
                ) : null}
              </div>
            </div>
            <div className="button_area text-center">
              <button type="submit" className="save_btn">
                Submit
              </button>
            </div>
          </form>
        </div>
      </Drawer>
    </>
  );
};

export default AddOffers;
