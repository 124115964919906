import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "@material-ui/lab/Pagination";
import { CSVLink } from "react-csv";
import {
  getDeactiveUsersCount,
  getUsersList,
  usersCount,
  usersList,
} from "../slice";
import SimpleBackdrop from "../../components/backdrop";
import HandleDelete from "./HandleDelete";
import { useToggle } from "../../app/hooks";
import ImgFluid from "../../components/image/ImgFluid";
import HandleActivate from "./HandleActivate";
import { Link } from "react-router-dom";

const DeactiveUser = () => {
  const dispatch = useDispatch();
  const status = useSelector((state) => state?.status);
  const limit = useSelector((state) => state?.limit);
  const data = useSelector((state) => state?.usersList);
  const count = useSelector((state) => state.usersCount);
  const [isOpen, toggle] = useToggle(false);
  const [isOpenDelete, toggleDelete] = useToggle(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [userID, setUserID] = useState("");

  useEffect(() => {
    dispatch(getDeactiveUsersCount({}));
  }, [dispatch]);

  const exportData = data?.data?.map((res) => {
    return {
      "Matrimony Id": res?.veer_id,
      "First Name": res?.first_name,
      "Middle Name": res?.middle_name,
      "Last Name": res?.last_name,
      email: res?.email,
      Mobile: res?.mobile_number,
      Gender: res?.gender,
      reason: res?.deactivated_reason_by_admin,
    };
  });

  useEffect(() => {
    dispatch(getUsersList({ tab_type: "deactivated", page, perpage: limit }));
  }, [dispatch, page, limit]);

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(
      getUsersList({ tab_type: "deactivated", page: 1, perpage: limit, search })
    );
  };

  useEffect(() => {
    return () => dispatch(usersList(null));
  }, [dispatch]);

  useEffect(() => {
    return () => dispatch(usersCount(null));
  }, [dispatch]);

  return (
    <div className="m-main-content">
      <div className="brand_main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-7">
              <div className="brand_head_data">
                <div className="brands_total deactive_user">
                  <img src="images/Deactivated-users-white.png" alt="user" />
                  <p className="t_brand_txt">Total Deactivated Users</p>
                  <h4 className="brand_num">{data?.totalCount || 0}</h4>
                </div>
              </div>
            </div>

            <div className="col-md-5">
              <form className="search_area" onSubmit={handleSearch}>
                <div className="top-search-bar p-top">
                  <input
                    type="text"
                    name="search"
                    value={search}
                    placeholder="Search by name/email/mobile."
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  {search ? (
                    <img
                      src="images/cancel.png"
                      className="search_icon pointer"
                      alt="clear"
                      onClick={() => setSearch("")}
                    />
                  ) : (
                    <img
                      src="images/search.png"
                      className="search_icon"
                      alt="search"
                    />
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        {exportData?.length > 0 ? (
          <CSVLink data={exportData || ""} filename={"Deactive User"}>
            <button type="submit" className="btn save_btnn mx-2">
              Export CSV
            </button>
          </CSVLink>
        ) : (
          ""
        )}
      </div>
      <div className="brand_table">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="brand_table_inner">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">S.No</th>
                        <th scope="col">Matrimony ID</th>
                        <th scope="col">Photo</th>
                        <th scope="col">Full Name</th>
                        <th scope="col">Gender</th>
                        <th scope="col">Phone Number</th>
                        <th scope="col">Reason</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.data?.length ? (
                        data.data.map((el, i) => (
                          <tr key={el._id}>
                            <td className="bt-non">{i + 1}</td>
                            <td className="bt-non">{el?.veer_id || "--"}</td>
                            <td className="bt-non">
                              <div className="p_pic">
                                <ImgFluid
                                  src={
                                    el?.profile_image ||
                                    el.profile_image_video_path
                                  }
                                />
                              </div>
                            </td>
                            <td className="bt-non">
                              {`${el.first_name} ${el.last_name}` || "N/A"}
                            </td>
                            <td className="bt-non">{el?.gender}</td>
                            <td className="bt-non">{el?.mobile_number}</td>

                            <td className="bt-non">
                              {el.deactivated_reason_by_admin || "N/A"}
                            </td>
                            <td className="bt-non">
                              <Link
                                to={`/deactive-user-detail/${el._id}`}
                                title="View"
                              >
                                <img
                                  src="images/view.png"
                                  className="action-view"
                                  alt="view"
                                />
                              </Link>
                              <span className="table-action">
                                <button
                                  style={{ background: "none", border: "none" }}
                                  title="Deactivate"
                                  onClick={() => {
                                    setUserID(el._id);
                                    toggle();
                                  }}
                                >
                                  <img
                                    src="images/on.png"
                                    className="action-view"
                                    alt="status"
                                  />
                                </button>
                                <button
                                  onClick={() => {
                                    setUserID(el._id);
                                    toggleDelete();
                                  }}
                                  style={{ background: "none", border: "none" }}
                                  title="Delete"
                                >
                                  <img
                                    src="images/delete.png"
                                    className="action-view"
                                    alt="delete"
                                  />
                                </button>
                              </span>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={9} style={{ padding: "10px" }}>
                            No Results Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  {data?.pages > 1 ? (
                    <Pagination
                      count={data.pages}
                      page={data.current}
                      onChange={(e, page) => setPage(page)}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SimpleBackdrop open={status === "loading"} />
      <HandleDelete
        isOpen={isOpenDelete}
        toggle={toggleDelete}
        userID={userID}
        page={page}
        userType="deactivated"
      />
      <HandleActivate
        isOpen={isOpen}
        toggle={toggle}
        userID={userID}
        page={page}
        userType="deactivated"
      />
    </div>
  );
};

export default DeactiveUser;
