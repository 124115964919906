import {
  getData,
  patchEncodedData,
  postEncodedData,
  postEncodedDataDelete,
  postEncodedDataPatch,
  getPostData,
} from ".";

export async function addUpdateStory(params) {
  return postEncodedData("/admin/add_update_story", params).then((data) => {
    return data;
  });
}

export async function fetchStory(params) {
  return getData("/admin/get_story?search&perpage&page", params).then(
    (data) => {
      return data;
    }
  );
}
export async function fetchStoryDetails(params) {
  return getData(`/admin/get_story_details/${params?.id}`).then((data) => {
    return data;
  });
}
export async function deleteStory(params) {
  return postEncodedDataDelete("/admin/remove_story", params).then((data) => {
    return data;
  });
}
export async function updateStatusBanner(params) {
  return postEncodedDataPatch("/admin/update_banner_status", params).then(
    (data) => {
      return data;
    }
  );
}
export async function fetchQuery(params) {
  return getData("/admin/user/enquiry_list?perpage&page&search", params).then(
    (data) => {
      return data;
    }
  );
}

export async function addUpdateQuery(params) {
  return patchEncodedData("/admin/user/add_response", params).then((data) => {
    return data;
  });
}
export async function fetchDashboard(params) {
  return getPostData(
    `/admin/dashboard?start_date=${params.start_date}&end_date=${params.end_date}&month=${params.month}&year`,
    params
  ).then((data) => {
    return data;
  });
}
