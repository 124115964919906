import { useState } from "react";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import { getUsersCount, getUsersList, idle, loading } from "../slice";
import { verifyUser } from "../../api/users";
import { useEffect } from "react";

const HandleVerify = ({
  isOpen,
  toggle,
  userID,
  page,
  userType,
  status,
  id,
}) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { goBack } = useHistory();
  const limit = useSelector((state) => state?.limit);
  const [reason, setReason] = useState("");

  useEffect(() => {
    document.body.classList.toggle("modal-open", isOpen);
  }, [isOpen]);

  const handleVerification = () => {
    if (userID) {
      dispatch(loading());
      const payload = {
        user_id: userID,
        status,
      };
      if (status === 2) {
        payload.reason = reason;
      }

      verifyUser(payload).then((response) => {
        if (!response?.error_code) {
          if (page) {
            dispatch(
              getUsersList({ tab_type: userType, page, perpage: limit })
            );
            dispatch(getUsersCount({}));
          } else {
            goBack();
          }
          toggle();
        }
        enqueueSnackbar(response?.message);
        dispatch(idle());
      });
    }
  };

  return (
    <>
      <div
        className={isOpen ? "modal fade show" : "modal fade"}
        id="delUserDetails"
        style={{ display: isOpen ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">
                {status === 1 && "Approve User"}
                {status === 2 && "Reject User"}
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={toggle}
              >
                &times;
              </button>
            </div>
            <div className="p-4">
              <h4 className="del_user text-center">
                Are you sure, you want to {status === 1 && "Approve"}
                {status === 2 && "Reject"} this user?
              </h4>
              {status === 2 ? (
                <div className="">
                  <textarea
                    className="form-control"
                    placeholder="Write reason..."
                    rows={4}
                    onChange={(event) => setReason(event.target.value)}
                  />
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="Del_btn"
                data-dismiss="modal"
                onClick={handleVerification}
              >
                Confirm
              </button>
              <button
                type="button"
                className="Can_btn"
                data-dismiss="modal"
                onClick={toggle}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      {isOpen && <div className="modal-backdrop fade show"></div>}
    </>
  );
};

HandleVerify.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  userID: PropTypes.string.isRequired,
  page: PropTypes.number,
  userType: PropTypes.string.isRequired,
  status: PropTypes.number.isRequired,
};

export default HandleVerify;
