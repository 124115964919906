import React, { useState, useEffect } from "react";
import { Drawer } from "antd";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
// import { getOffer, getOfferDetails } from '../slice';
import { getBanner, getBannerDetails } from "../../slice";
import CircularProgress from "@material-ui/core/CircularProgress";
import { fileUploader } from "../../../api/accounts";
import { addUpdateBanner } from "../../../api/banner";
import S3FileUpload from "react-s3";
window.Buffer = window.Buffer || require("buffer").Buffer;

const AddBanner = ({ show, handleShow, id, status, search }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.bannerDetail);
  const { enqueueSnackbar } = useSnackbar();
  const [viewportWidth, setViewportWidth] = useState("");
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    if (typeof window.innerWidth != "undefined") {
      setViewportWidth(window.innerWidth);
    }
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getBannerDetails({ id }));
    }
  }, [dispatch, id]);

  const formik = useFormik({
    initialValues: {
      image: id ? data?.image : "",
      validity: id ? data?.validity?.substr(0, 10) : "",
      description: id ? data?.description : "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      image: Yup.string().required("*Required"),
      validity: Yup.string().required("*Required"),
      description: Yup.string().required("*Required"),
    }),
    onSubmit: (values, { resetForm }) => {
      const { image, validity, description } = values;
      const payload = {
        image: image,
        validity: validity,
        description: description,
      };
      if (id) {
        payload.bannerId = id;
      }
      addUpdateBanner(payload).then((response) => {
        // setSaving(false);
        enqueueSnackbar(response?.message);
        if (response?.error_code === 0) {
          resetForm({});
          dispatch(getBanner({}));
          handleShow();
        }
      });
    },
  });
  const handleImageUpload = (event) => {
    formik.setFieldError("image", "");
    const file = event.target.files[0];
    const fileType = file.type.split("/")[0];
    if (fileType !== "image") {
      formik.setFieldError("image", "Only image files are accepted");
    } else if (fileType === "image" && Math.floor(file.size / 1000000) > 5) {
      formik.setFieldError("image", "File size should not exceed 5 MB");
    } else {
      setUploading(true);
      let file = event.target.files[0];
      const config = {
        bucketName: "appsinvo-stag-dev",
        region: "ap-south-1",
        accessKeyId: "AKIAVMOPKAV4WQ7QUNHV",
        secretAccessKey: "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",
      };
      S3FileUpload.uploadFile(file, config)
        .then((data) => {
          //  console.log("RES", data)
          formik.setFieldValue("image", data.location);
          setUploading(false);
        })
        .catch((err) => console.error("ERR", err));
    }
  };

  return (
    <>
      <Drawer
        placement="right"
        onClose={handleShow}
        visible={show}
        width={viewportWidth > 700 ? "40%" : "100%"}
      >
        <div className="px-5 pb-5">
          <div className="text-center">
            <h2 className="offcanvas-title mb-3">
              {id ? "Edit Banner" : "Add Banner"}{" "}
            </h2>
          </div>
          <form className="form-style mt-5" onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                <div className="user_img_main">
                  <div className="user_img_inner">
                    {formik.touched.image && formik.errors.image ? (
                      <span className="error">{formik.errors.image}</span>
                    ) : null}
                    {!uploading && formik.values.image ? (
                      <img
                        className="usr-img"
                        src={
                          formik.values.image.preview
                            ? formik.values.image.preview
                            : formik.values.image
                        }
                        alt="Upload Icon"
                      />
                    ) : uploading ? (
                      <CircularProgress />
                    ) : (
                      <img
                        src="images/favicon.png"
                        alt="user"
                        className="usr-img"
                      />
                    )}

                    <input
                      type="file"
                      name=""
                      id="picImg"
                      onChange={handleImageUpload}
                    />
                    <label htmlFor="picImg" className="slt_pic">
                      <img src="images/Plus.png" alt="Add" />
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                <div className="form-groupp">
                  <label>Validity</label>
                  <input
                    type="date"
                    className="form-control add_user_info"
                    placeholder="Validity Date"
                    {...formik.getFieldProps("validity")}
                  />
                  {/* <img src="images/dollar.png" className="input-img" alt="" /> */}
                </div>
                {formik.touched.validity && formik.errors.validity ? (
                  <span className="error">{formik.errors.validity}</span>
                ) : null}
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                <label>Description</label>
                <div className="form-groupp">
                  <textarea
                    className="form-control add_user_info"
                    rows={10}
                    {...formik.getFieldProps("description")}
                    placeholder="Description"
                  ></textarea>
                  {/* <img src="images/dollar.png" className="input-img" alt="" /> */}
                </div>
                {formik.touched.description && formik.errors.description ? (
                  <span className="error">{formik.errors.description}</span>
                ) : null}
              </div>
            </div>
            <div className="button_area text-center">
              <button type="submit" className="save_btn">
                Submit
              </button>
            </div>
          </form>
        </div>
      </Drawer>
    </>
  );
};

export default AddBanner;
