import { getData, postEncodedData } from ".";

export async function fetchUsersCount(params) {
  return getData("/admin/user/counter", params).then((data) => {
    return data;
  });
}

export async function fetchDeactiveUsersCount(params) {
  return getData("/admin/user/total_deactivated_user", params).then((data) => {
    return data;
  });
}

export async function fetchUsersList(params) {
  return getData("/admin/user/list", params).then((data) => {
    return data;
  });
}

export async function fetchParentCount(params) {
  return getData("/admin/user/get_parent_detail_limit", params).then((data) => {
    return data;
  });
}

export async function deleteUser(params) {
  return postEncodedData("/admin/user/delete", params).then((data) => {
    return data;
  });
}

export async function deleteUserBioData(params) {
  return postEncodedData("/admin/user/remove_bio_data", params).then((data) => {
    return data;
  });
}

export async function updateParentCount(params) {
  return postEncodedData("/admin/user/edit_parent_detail_limit", params).then(
    (data) => {
      return data;
    }
  );
}
export async function verifyUser(params) {
  return postEncodedData("/admin/user/user_approve_reject", params).then(
    (data) => {
      return data;
    }
  );
}

export async function verifyMultipleUsers(params) {
  return postEncodedData("/admin/user/aproved_all_users", params).then(
    (data) => {
      return data;
    }
  );
}
export async function assignMembership(params) {
  return postEncodedData("/admin/user/assign_subscription", params).then(
    (data) => {
      return data;
    }
  );
}
export async function verifyProfilePic(params) {
  return postEncodedData("/admin/user/user_approve_reject", params).then(
    (data) => {
      return data;
    }
  );
}

export async function addUser(params) {
  return postEncodedData("/admin/user/add_new_user", params).then((data) => {
    return data;
  });
}

export async function editUser(params) {
  return postEncodedData("/admin/user/edit_user", params).then((data) => {
    return data;
  });
}

export async function editParentsData(params) {
  return postEncodedData("/admin/user/edit_parent_detail", params).then(
    (data) => {
      return data;
    }
  );
}

export async function fetchUserDetails(params) {
  return getData("/admin/user/new__reject_user_details", params).then(
    (data) => {
      return data;
    }
  );
}
export async function fetchUserFamilyIncomeDetails(params) {
  return getData("/get_family_income_list", params).then((data) => {
    return data;
  });
}

export async function fetchMutuallyLikedProfiles(params) {
  return getData("/admin/user/mutually_user_list", params).then((data) => {
    return data;
  });
}

export async function fetchMyMatches(params) {
  return getData("/user/user_list", params).then((data) => {
    return data;
  });
}

export async function fetchSeenProfiles(params) {
  return getData("/user/seen_user_list", params).then((data) => {
    return data;
  });
}
export async function fetchVisitorsProfiles(params) {
  return getData("/user/profile_visitor_list", params).then((data) => {
    return data;
  });
}
export async function fetchShortlistProfiles(params) {
  return getData("/user/getAllShortListProfile", params).then((data) => {
    return data;
  });
}

export async function fetchInterestSentProfiles(params) {
  return getData("/admin/user/interest_sent_user_list", params).then((data) => {
    return data;
  });
}

export async function fetchInterestReceivedProfiles(params) {
  return getData("/admin/user/interest_receive_user_list", params).then(
    (data) => {
      return data;
    }
  );
}

export async function fetchMyInterestDeclinedProfiles(params) {
  return getData("/admin/user/my_interest_declined_user_list", params).then(
    (data) => {
      return data;
    }
  );
}

export async function fetchInterestDeclinedByMeProfiles(params) {
  return getData("/admin/user/interest_declined_user_list_by_me", params).then(
    (data) => {
      return data;
    }
  );
}

export async function fetchBioDataAccept(params) {
  return getData("/get-all-bio-data-request", params).then((data) => {
    return data;
  });
}

export async function fetchBlockedProfiles(params) {
  return getData("/admin/user/blocked_user_list", params).then((data) => {
    return data;
  });
}

//

export async function updateMatchLimit(params) {
  return postEncodedData("/admin/add_update_limit_listing", params).then(
    (data) => {
      return data;
    }
  );
}

export async function fetchMatchLimit(params) {
  return getData("/admin/get_limit_listing", params).then((data) => {
    return data;
  });
}

export async function updateSubscription(params) {
  return postEncodedData("/admin/add_update_subscription", params).then(
    (data) => {
      return data;
    }
  );
}

export async function updatePackage(params) {
  return postEncodedData("/admin/add_update_package", params).then((data) => {
    return data;
  });
}

export async function fetchPackage(params) {
  return getData("/admin/get_package_list", params).then((data) => {
    return data;
  });
}
