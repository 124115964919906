import React, { useState, useEffect } from "react";
import { Drawer } from "antd";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { addUpdateCourses, addUpdateSpecialization } from "../../api/offer";
import { getHigherEducation } from "../slice";
import { getCourses } from "../slice";
import { getCourseDetails } from "../slice";
import { getCourseList } from "../slice";
import { getSpecialization } from "../slice";
import { getSpecializationDetails } from "../slice";

const AddSpecialization = ({ show, handleShow, id, status, search, page }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.specializationDetail);
  const { enqueueSnackbar } = useSnackbar();
  const [viewportWidth, setViewportWidth] = useState("");
  const [higherEducation, setHigherEducation] = useState("");
  const education = useSelector((state) => state?.higherEducation);
  const courses = useSelector((state) => state?.courseList);

  useEffect(() => {
    if (typeof window.innerWidth != "undefined") {
      setViewportWidth(window.innerWidth);
    }
  }, []);
  useEffect(() => {
    dispatch(getHigherEducation());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getSpecializationDetails({ id }));
  }, []);
  const handleChangeHigher = (e) => {
    setHigherEducation(e.target.value);
    dispatch(getCourseList({ higher_education: [e.target.value] }));
  };

  const formik = useFormik({
    initialValues: {
      course: id ? data?.course : "",
      name: id ? data?.name : "",
      highereducation: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      course: Yup.string().required("*Required"),
      name: Yup.string().required("*Required"),
    }),
    onSubmit: (values, { resetForm }) => {
      const { course, name } = values;
      const payload = {
        course: course,
        name: name,
        higher_education: higherEducation,
      };
      if (id) {
        payload.specializationId = id;
      }
      addUpdateSpecialization(payload).then((response) => {
        enqueueSnackbar(response?.message);
        if (response?.error_code === 0) {
          resetForm({});
          dispatch(getSpecialization({ page: page }));
          handleShow();
        }
      });
    },
  });

  return (
    <>
      <Drawer
        placement="right"
        onClose={handleShow}
        visible={show}
        width={viewportWidth > 700 ? "40%" : "100%"}
      >
        <div className="px-5 pb-5">
          <div className="text-center">
            <h2 className="offcanvas-title mb-3">
              {id ? "Edit Specialization" : "Add Specialization"}{" "}
            </h2>
          </div>
          <form className="form-style mt-5" onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                <label className="text_label">Select Higher Education</label>
                <select
                  className="form-control add_user_info"
                  // {...formik.getFieldProps("highereducation")}
                  onChange={handleChangeHigher}
                >
                  <option value="">Select Higher Education</option>
                  {education?.map((el, i) => {
                    return <option value={el?._id}>{el?.name}</option>;
                  })}
                </select>
                {formik.touched.highereducation &&
                formik.errors.highereducation ? (
                  <span className="error">{formik.errors.highereducation}</span>
                ) : null}
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                <label className="text_label">Select Course</label>
                <select
                  className="form-control add_user_info"
                  {...formik.getFieldProps("course")}
                >
                  <option value="">Select Course</option>
                  {courses?.map((el, i) => {
                    return <option value={el?._id}>{el?.name}</option>;
                  })}
                </select>
                {formik.touched.course && formik.errors.course ? (
                  <span className="error">{formik.errors.course}</span>
                ) : null}
              </div>

              <div className="col-sm-12 col-md-12 col-lg-12 mb-4 mt-4">
                <div className="form-groupp">
                  <label className="text_label">Specialization Name</label>
                  <div className="input-containerr">
                    <input
                      type="text"
                      className="form-control add_user_info"
                      {...formik.getFieldProps("name")}
                      placeholder="Specialization Name"
                    />
                    {/* <img src="images/dollar.png" className="input-img" alt="" /> */}
                  </div>
                  {formik.touched.name && formik.errors.name ? (
                    <span className="error">{formik.errors.name}</span>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="button_area text-center">
              <button type="submit" className="save_btn">
                Submit
              </button>
            </div>
          </form>
        </div>
      </Drawer>
    </>
  );
};

export default AddSpecialization;
