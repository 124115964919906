import React, { useEffect, useState } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
// import _ from "lodash";

import { fetchRashi, getUserDetails } from "../../slice";
import { convertTime12to24, getAge, min18Years } from "../../../utils";
import { editHoroscope } from "../../../api/edit";
import SimpleBackdrop from "../../../components/backdrop";
import { Link } from "react-router-dom";
import UserProfile from "../details/Profile";

const maxAge = min18Years();

const HoroscopeDetails = () => {
  const dispatch = useDispatch();
  const { userID } = useParams();
  const { goBack } = useHistory();
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const data = useSelector((state) => state.userDetails);
  const status = useSelector((state) => state?.status);
  const list = useSelector((state) => state.rashiList);
  const [saving, setSaving] = useState(false);

  useEffect(
    () =>
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      }),
    []
  );

  useEffect(() => {
    dispatch(fetchRashi({}));
  }, []);
  useEffect(() => {
    if (userID) {
      dispatch(getUserDetails({ user_id: userID }));
    }
  }, [dispatch, userID]);

  const formik = useFormik({
    initialValues: {
      nadi: data?.horoscope_details?.nadi || "",
      time: data?.horoscope_details?.time
        ? convertTime12to24(data.horoscope_details.time)
        : "",
      rashi: data?.horoscope_details?.rashi || "",
      manglik: data?.horoscope_details?.manglik || "",
      gan: data?.horoscope_details?.gan || "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      nadi: Yup.string().required("*Required"),
      rashi: Yup.string().required("*Required"),
      manglik: Yup.string().required("*Required"),
      time: Yup.string().required("*Required"),
    }),
    onSubmit: (values, { resetForm }) => {
      const { manglik, rashi, nadi, time, gan } = values;
      setSaving(true);
      const payload = {
        userid: userID,
        nadi: nadi,
        manglik: manglik,
        rashi: rashi,
        time: time,
        gan: gan,
      };

      editHoroscope(payload).then((response) => {
        setSaving(false);
        enqueueSnackbar(response?.message);
        if (response?.error_code === 0) {
          dispatch(getUserDetails({ user_id: userID }));
        }
      });
    },
  });

  useEffect(() => {
    const date = formik.values.birthday;

    if (date) {
      const age = getAge(date);
      formik.setFieldValue("age", age);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.birthday]);

  return (
    <div className="m-main-content">
      <div className="manage_new_user_details p-5">
        <div
          className="new_user_detail_heading"
          style={{ position: "relative" }}
        >
          <button className="back-arrow" onClick={goBack}>
            <img src="images/back.png" alt="back" />
          </button>
          <h2 className="new_user_txt">
            {pathname.includes("/edit")
              ? "Edit Horoscope Details"
              : "Add Horoscope Details"}
          </h2>
          <UserProfile data={data} />
          <div className="edit-span-button">
            <span className="position-relative edit-icon-list">
              <img src="images/edit.png" className="action-view" alt="edit" />
              <div className="edit-pages">
                <Link to={`/users/edit/${userID}`}>Basic Details</Link>
                {/* <Link to={`/users/edit-gender/${userID}`}>Gender</Link> */}
                <Link to={`/users/edit-marital-status/${userID}`}>
                  Personal Details
                </Link>
                <Link to={`/users/edit-religion/${userID}`}>
                  Religion Details
                </Link>
                <Link to={`/users/body-details/${userID}`}>Body Details</Link>
                <Link to={`/users/edit-occupation-details/${userID}`}>
                  Professional Details
                </Link>
                <Link to={`/users/edit-family-details/${userID}`}>
                  Family Details
                </Link>
                <Link to={`/users/edit-lifestyle/${userID}`}>Lifestyle</Link>
                <Link to={`/users/edit-hobbies/${userID}`}>Hobbies</Link>
                <Link to={`/users/edit-horoscope-details/${userID}`}>
                  Horoscope Details
                </Link>
                <Link to={`/users/edit-partner-preferences/${userID}`}>
                  My Partner Preferences
                </Link>
                <Link to={`/users/about-me/${userID}`}>About Me</Link>
                <Link to={`/users/my-document/${userID}`}>My Document</Link>
              </div>
            </span>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
              <form className="row form-style" onSubmit={formik.handleSubmit}>
                <div className="form-group col-sm-12 col-md-12 text-center mb-4"></div>
                <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                  <label className="num">Nadi</label>
                  <div className="input-container add_user_info">
                    <select
                      className="form-control ps-4"
                      {...formik.getFieldProps("nadi")}
                    >
                      <option value="">Select Nadi</option>
                      <option value="Adya">Adya</option>
                      <option value="Madhya">Madhya</option>
                      <option value="Antya">Antya</option>
                    </select>
                    <img
                      src="images/Path 17786.png"
                      className="input-img pass"
                      alt=""
                    />
                    {formik.touched.nadi && formik.errors.nadi ? (
                      <span className="error">{formik.errors.nadi}</span>
                    ) : null}
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                  <label className="num">Manglik</label>
                  <div className="input-container add_user_info">
                    <select
                      className="form-control ps-4"
                      {...formik.getFieldProps("manglik")}
                    >
                      <option value="">Select</option>
                      <option value="Yes">Yes</option>
                      <option value="Yes but Mild">Yes but Mild</option>
                      <option value="No">No</option>
                    </select>
                    <img
                      src="images/astrology.png"
                      className="input-img pass"
                      alt=""
                    />
                    {formik.touched.manglik && formik.errors.manglik ? (
                      <span className="error">{formik.errors.manglik}</span>
                    ) : null}
                  </div>
                </div>

                <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                  <label className="num">Birth Time</label>
                  <div className="input-container add_user_info">
                    <input
                      type="time"
                      className="form-control ps-4"
                      placeholder="Time of Birth"
                      {...formik.getFieldProps("time")}
                      title="Time of Birth"
                    />
                    <img src="images/time.png" className="input-img" alt="" />
                    {formik.touched.time && formik.errors.time ? (
                      <span className="error">{formik.errors.time}</span>
                    ) : null}
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                  <label className="num">Rashi</label>
                  <div className="input-container add_user_info">
                    <select
                      className="form-control ps-4"
                      {...formik.getFieldProps("rashi")}
                    >
                      <option value="">Select Rashi</option>
                      {list?.data?.rashi_list?.map((res) => {
                        return <option value={res?.name}>{res?.title}</option>;
                      })}
                    </select>
                    <img
                      src="images/astrology.png"
                      className="input-img pass"
                      alt=""
                    />
                    {formik.touched.rashi && formik.errors.rashi ? (
                      <span className="error">{formik.errors.rashi}</span>
                    ) : null}
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                  <label className="num">Gan</label>
                  <div className="input-container add_user_info">
                    <select
                      className="form-control ps-4"
                      {...formik.getFieldProps("gan")}
                    >
                      <option value="">Select Gan</option>
                      {list?.data?.gan_list?.map((res) => {
                        return <option value={res?.name}>{res?.title}</option>;
                      })}
                    </select>
                    <img
                      src="images/astrology.png"
                      className="input-img pass"
                      alt=""
                    />
                    {formik.touched.rashi && formik.errors.rashi ? (
                      <span className="error">{formik.errors.rashi}</span>
                    ) : null}
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 text-center">
                  <div className="button_area">
                    <button
                      type="submit"
                      className="save_btn"
                      disabled={saving}
                    >
                      {saving ? (
                        <CircularProgress color="inherit" />
                      ) : pathname.includes("/edit") ? (
                        "Save Changes"
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {status === "loading" && <SimpleBackdrop open={status === "loading"} />}
    </div>
  );
};

export default HoroscopeDetails;
