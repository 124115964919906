import React, { useState, useEffect } from "react";
import { Drawer } from "antd";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { addUpdateOccupation } from "../../api/offer";
import { getOfferDetails } from "../slice";
import { getAllUsers } from "../slice";
import { getOccupation } from "../slice";

const AddOccupation = ({ show, handleShow, id, status, search }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.offerDetail);
  const { enqueueSnackbar } = useSnackbar();
  const [viewportWidth, setViewportWidth] = useState("");

  useEffect(() => {
    if (typeof window.innerWidth != "undefined") {
      setViewportWidth(window.innerWidth);
    }
  }, []);

  // useEffect(() => {
  //     dispatch(getOfferDetails({ id }))
  // }, [dispatch,])

  const formik = useFormik({
    initialValues: {
      employment: "",
      name: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      employment: Yup.string().required("*Required"),
      name: Yup.string().required("*Required"),
    }),
    onSubmit: (values, { resetForm }) => {
      const { name, employment } = values;
      const payload = {
        name: name,
        employment_type: employment,
      };
      if (id) {
        payload.offerId = id;
      }
      addUpdateOccupation(payload).then((response) => {
        // setSaving(false);
        enqueueSnackbar(response?.message);
        if (response?.error_code === 0) {
          resetForm({});
          dispatch(getOccupation({}));
          handleShow();
        }
      });
    },
  });

  return (
    <>
      <Drawer
        placement="right"
        onClose={handleShow}
        visible={show}
        width={viewportWidth > 700 ? "40%" : "100%"}
      >
        <div className="px-5 pb-5">
          <div className="text-center">
            <h2 className="offcanvas-title mb-3">
              {id ? "Edit Occupation" : "Add Occupation"}{" "}
            </h2>
          </div>
          <form className="form-style mt-5" onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                <label className="text_label">Employment</label>
                <select
                  className="form-control add_user_info"
                  {...formik.getFieldProps("employment")}
                >
                  <option value="">Select Employment</option>
                  <option value="Government">Government</option>
                  <option value="Private">Private</option>
                  <option value="Business">Business</option>
                  <option value="Defence">Defence</option>
                  <option value="Self employed">Self Employed</option>
                  <option value="Private Service & Business">
                    Private Service & Business
                  </option>
                  <option value="Government Service & Business">
                    Government Service & Business
                  </option>
                  <option value="Self Employed & Business">
                    Self Employed & Business
                  </option>
                </select>
                {formik.touched.name && formik.errors.name ? (
                  <span className="error">{formik.errors.name}</span>
                ) : null}
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                <div className="form-groupp">
                  <label className="text_label">Occupation Name</label>
                  <input
                    type="text"
                    className="form-control add_user_info"
                    placeholder="Name"
                    {...formik.getFieldProps("name")}
                  />
                  {/* <img src="images/dollar.png" className="input-img" alt="" /> */}
                </div>
                {formik.touched.name && formik.errors.name ? (
                  <span className="error">{formik.errors.name}</span>
                ) : null}
              </div>
            </div>
            <div className="button_area text-center">
              <button type="submit" className="save_btn">
                Submit
              </button>
            </div>
          </form>
        </div>
      </Drawer>
    </>
  );
};

export default AddOccupation;
