import React, { useState, useEffect } from "react";
import countryProvinces from "../../../utils/countryProvinces.json";
import { useFormik } from "formik";

const NativeState = ({
  checkedItems,
  setCheckedItems,
  stateProvinces,
  setStateProvinces,
}) => {
  const formik = useFormik({
    initialValues: {
      occupation_country: [],
      occupation_state: [],
      occupation_city: [],
      country: [],
    },
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {},
  });

  const handleCheckOptions = (event) => {
    const { value, checked } = event.target;

    if (checked) {
      setCheckedItems([...checkedItems, value]); // add the value to the array
      // formik.setFieldValue("country", [...checkedItems, value]);
    } else {
      setCheckedItems(checkedItems.filter((item) => item !== value)); // remove the value from the array
    }
  };

  useEffect(() => {
    const country = formik.values.country;
    if (country?.length > 0) {
      let allProvinces = [];
      country.forEach((c) => {
        const provinces = countryProvinces.find(
          (el) => el.name === c
        )?.stateProvinces;
        if (provinces) {
          allProvinces = [...allProvinces, ...provinces];
        }
      });
      setStateProvinces(allProvinces);
    }
  }, [formik.values.country]);

  return (
    <div className="gov text-center mb-4 mt-3">
      <h4>State List</h4>
      {stateProvinces?.map((res, i) => {
        return (
          <div className="" key={i}>
            <input
              type="checkbox"
              id="notifyAllUsers"
              name="notifyAllUsers"
              value={res?.name}
              // checked={checkedItems.includes(res?.id)}
              onChange={handleCheckOptions}
            />
            <label htmlFor="groupA" className="mx-3">
              {res?.name}
            </label>
          </div>
        );
      })}
    </div>
  );
};

export default NativeState;
