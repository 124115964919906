import React, { useState, useEffect } from "react";
import { Drawer } from "antd";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { getEarningDetails } from "../slice";
import CircularProgress from "@material-ui/core/CircularProgress";

const RevenueDetail = ({ show, handleShow, id }) => {
  const dispatch = useDispatch();
  const { goBack } = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [viewportWidth, setViewportWidth] = useState("");
  const data = useSelector((state) => state.earningDetail);
  const [saving, setSaving] = useState(false);
  const [usersDetail, setUserDetail] = useState();

  useEffect(() => {
    if (typeof window.innerWidth != "undefined") {
      setViewportWidth(window.innerWidth);
    }
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getEarningDetails({ date: id }));
    }
  }, [dispatch, id]);

  return (
    <>
      <Drawer
        placement="right"
        onClose={handleShow}
        visible={show}
        width={viewportWidth > 700 ? "40%" : "100%"}
      >
        <div className="px-5 pb-5">
          <div className="row">
            <div className="text-center">
              <h2 className="offcanvas-title mb-3">Earning Details</h2>
            </div>

            <div className="col-sm-12 col-md-12 col-lg-12">
              <hr />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6 mt-2">
              <div className="position-relative ps-4 text-capitalize">
                <img
                  src="assets/images/user.png"
                  className="detailimg"
                  alt=""
                />
                <h6>user ID</h6>
                <p className="mb-0">{usersDetail?.userID || ""}</p>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6 mt-2">
              <div className="position-relative ps-4">
                <h6>Amount</h6>
                <p className="mb-0">
                  {usersDetail?.email ? usersDetail?.email || "" : "NA"}
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12">
              <hr />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6 mt-2">
              <div className="position-relative ps-4 text-capitalize">
                <h6>Currency</h6>
                <p className="mb-0">
                  {usersDetail?.countryCode || ""}{" "}
                  {usersDetail?.mobileNumber || ""}
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6 mt-2">
              <div className="position-relative ps-4 text-capitalize">
                <h6>Status</h6>
                <p className="mb-0">
                  <span className="codeCopup">
                    {usersDetail?.referralCode || ""}
                  </span>
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12">
              <hr />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6 mt-2">
              <div className="position-relative ps-4 text-capitalize">
                <h6>Plan Type</h6>
                <p className="mb-0">{usersDetail?.accountHolderName || ""}</p>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6 mt-2">
              <div className="position-relative ps-4 text-capitalize">
                <h6>Date</h6>
                <p className="mb-0">{usersDetail?.bankAccountNumber || ""}</p>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12">
              <hr />
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default RevenueDetail;
