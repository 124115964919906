import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSubscription, getUsersList, usersCount, usersList } from "../slice";

import SimpleBackdrop from "../../components/backdrop";
import { useToggle } from "../../app/hooks";
import AddSubscription from "./AddSubscription";
import DeleteSubscription from "./DeleteSubscription";
import UpdateSubscription from "./UpdateSubscription";
import Pagination from "react-js-pagination";

const Subscriptionlist = () => {
  const dispatch = useDispatch();
  const status = useSelector((state) => state?.status);
  const limit = useSelector((state) => state?.limit);
  const data = useSelector((state) => state?.usersList);
  const count = useSelector((state) => state.usersCount);
  const [id, setId] = useState("");
  const [isOpen, toggle] = useToggle(false);
  const [isOpenDelete, toggleDelete] = useToggle(false);
  const [isOpenEdit, toggleEdit] = useToggle(false);
  const [isOpenStatus, toggleStatus] = useToggle(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [status2, setStatus2] = useState("");
  const [userID, setUserID] = useState("");
  const dataSubscription = useSelector((state) => state.subscription);
  const [activePage, setActivePage] = useState(1);

  useEffect(() => {
    dispatch(getSubscription({ page: 1 }));
  }, [dispatch]);
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    // setPageNo(pageNumber)
    dispatch(getSubscription({ page: pageNumber }));
  };
  
  // const handleSearch = (e) => {
  //     e.preventDefault();
  //     dispatch(
  //         getUsersList({ tab_type: "deactivated", page: 1, perpage: limit, search })
  //     );
  // };
  // useEffect(() => {
  //     return () => dispatch(usersList(null));
  // }, [dispatch]);

  // useEffect(() => {
  //     return () => dispatch(usersCount(null));
  // }, [dispatch]);

  return (
    <div className="m-main-content">
      <div className="brand_main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4">
              <div className="brand_head_data">
                <div className="brands_total deactive_user">
                  {/* <img src="images/Deactivated-users-white.png" alt="user" /> */}
                  <p className="t_brand_txt">Total Subscription Package</p>
                  <h4 className="brand_num">
                    {dataSubscription?.total_subscriptions}
                  </h4>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end col-md-8">
              {/* <form className="search_area" onSubmit={handleSearch}>
                                <div className="top-search-bar p-top">
                                    <input
                                        type="text"
                                        name="search"
                                        value={search}
                                        placeholder="Search user by name"
                                        onChange={(e) => setSearch(e.target.value)}
                                    />
                                    {search ? (
                                        <img
                                            src="images/cancel.png"
                                            className="search_icon pointer"
                                            alt="clear"
                                            onClick={() => setSearch("")}
                                        />
                                    ) : (
                                        <img
                                            src="images/search.png"
                                            className="search_icon"
                                            alt="search"
                                        />
                                    )}
                                </div>
                            </form> */}
              <span className="brand_add mt-5" onClick={toggle}>
                <img src="images/add.png" alt="add" />
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="brand_table">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="brand_table_inner">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        {/* <th scope="col">S.No</th> */}
                        <th scope="col">Package</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Validity</th>
                        <th scope="col">No Of Requests</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataSubscription?.subscription_list?.length ? (
                        dataSubscription?.subscription_list.map((el, i) => (
                          <tr>
                            {/* <td className="bt-non">
                                                                {i + 1}
                                                            </td> */}
                            <td className="bt-non">{el?.package || ""}</td>

                            <td className="bt-non">{el?.amount + "₹" || ""}</td>
                            <td className="bt-non">
                              {el?.validity === 0 ? `Lifetime` : ""}
                              {el?.validity ? `${el?.validity} Months` : ""}
                            </td>
                            <td className="bt-non">
                              {el?.number_of_requests
                                ? `${el?.number_of_requests} Requests`
                                : "NA"}
                            </td>

                            <td className="bt-non">
                              <span className="table-action">
                                <button
                                  style={{ background: "none", border: "none" }}
                                  title="Deactivate"
                                  onClick={() => {
                                    setId(el._id);
                                    setStatus2(el.isActive);
                                    toggleStatus();
                                  }}
                                >
                                  <img
                                    src={
                                      el?.isActive === true
                                        ? "images/on.png"
                                        : "images/off.png"
                                    }
                                    className="action-view"
                                    alt="status"
                                  />
                                </button>
                                <button
                                  onClick={() => {
                                    setId(el._id);
                                    toggleEdit();
                                  }}
                                  style={{ background: "none", border: "none" }}
                                  title="Edit"
                                >
                                  <img
                                    src="images/edit.png"
                                    className="action-view"
                                    alt="delete"
                                  />
                                </button>
                                <button
                                  onClick={() => {
                                    setId(el._id);
                                    toggleDelete();
                                  }}
                                  style={{ background: "none", border: "none" }}
                                  title="Delete"
                                >
                                  <img
                                    src="images/delete.png"
                                    className="action-view"
                                    alt="delete"
                                  />
                                </button>
                              </span>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={7} style={{ padding: "10px" }}>
                            No Results Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  {dataSubscription?.total_subscriptions >= 9 ? (
                    <div className="mt-4">
                      <Pagination
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={dataSubscription?.total_subscriptions}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SimpleBackdrop open={status === "loading"} />
      {isOpen ? <AddSubscription show={isOpen} handleShow={toggle} /> : ""}
      {isOpenEdit ? (
        <AddSubscription show={isOpenEdit} handleShow={toggleEdit} id={id} />
      ) : (
        ""
      )}
      <DeleteSubscription
        isOpen={isOpenDelete}
        toggle={toggleDelete}
        id={id}
        page={page}
        userType="deactivated"
      />
      <UpdateSubscription
        isOpen={isOpenStatus}
        toggle={toggleStatus}
        id={id}
        status={status2}
        page={page}
        userType="deactivated"
      />
    </div>
  );
};

export default Subscriptionlist;
