import { useState } from "react";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { getUserDetails } from "../slice";
import axios from "axios";
import { getCookie } from "../../utils";
import { getPhotos } from "../slice";

const ApproveRejectPhotos = ({
  isOpen,
  toggle,
  userID,
  page,
  status,
  imageId,
}) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { goBack } = useHistory();
  const limit = useSelector((state) => state?.limit);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    document.body.classList.toggle("modal-open", isOpen);
  }, [isOpen]);

  const handleUpdate = () => {
    setLoading(true);
    axios({
      method: "patch",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        Authorization: getCookie("clszdi"),
      },
      url: `https://api.pairsindia.in/admin/user/update_user_profile_image_status`,
      data: {
        user_id: userID,
        profile_image_id: imageId,
        is_verified: status === 1 ? 1 : 2, // 1 for approved // 2 for reject // 0 for N/A
      },
    }).then((res) => {
      setLoading(false);
      if (res.data.error_code === 0) {
        enqueueSnackbar(res.data.message);
        dispatch(getPhotos({ page: page }));
        toggle();
      }
    });
  };

  return (
    <>
      <div
        className={isOpen ? "modal fade show" : "modal fade"}
        id="delUserDetails"
        style={{ display: isOpen ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">
                {status === 1 && "Approve Picture"}
                {status === 2 && "Reject Picture"}
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={toggle}
              >
                &times;
              </button>
            </div>
            <div className="p-4">
              <h4 className="del_user text-center">
                Are you sure, you want to {status === 1 && "Approve"}
                {status === 2 && "Reject"} picture?
              </h4>
            </div>
            <div className="modal-footer">
              {loading ? (
                <button className="Del_btn" type="button" disabled>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span className="sr-only"></span>
                </button>
              ) : (
                <button
                  type="button"
                  className="Del_btn"
                  data-dismiss="modal"
                  onClick={handleUpdate}
                >
                  Confirm
                </button>
              )}
              <button
                type="button"
                className="Can_btn"
                data-dismiss="modal"
                onClick={toggle}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      {isOpen && <div className="modal-backdrop fade show"></div>}
    </>
  );
};

ApproveRejectPhotos.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  userID: PropTypes.string.isRequired,
  page: PropTypes.number,
  userType: PropTypes.string.isRequired,
  status: PropTypes.number.isRequired,
};

export default ApproveRejectPhotos;
