import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  onMessage,
  isSupported,
} from "firebase/messaging";
import Cookies from "universal-cookie";
const cookie = new Cookies();

var firebaseConfig = {
  apiKey: "AIzaSyBjiNnnGLcLKVT5FWhAuWUdVYAAHfOuR0c",
  authDomain: "veer-matrimony.firebaseapp.com",
  projectId: "veer-matrimony",
  storageBucket: "veer-matrimony.appspot.com",
  messagingSenderId: "982469213653",
  appId: "1:982469213653:web:c5ec0e7d32b21d6670915c",
  // measurementId: "G-7C08CMGDWG"
};

const firebaseApp = initializeApp(firebaseConfig);
export const messaging = (async () => {
  try {
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
      return getMessaging(firebaseApp);
    }
    //   console.log('Firebase not supported this browser');
    return null;
  } catch (err) {
    //   console.log(err);
    return null;
  }
})();
// export const messaging = getMessaging(firebaseApp);
export const onMessageListener = async () =>
  new Promise((resolve) =>
    (async () => {
      const messagingResolve = await messaging;
      onMessage(messagingResolve, (payload) => {
        //      toast.success(payload.notification.title, {
        //     //   theme: '',
        //       position: "top-center",
        //       autoClose: 4000,
        //       hideProgressBar: false,
        //       closeOnClick: true,
        //       pauseOnHover: true,
        //       draggable: true,
        //       progress: undefined,
        //   })
        resolve(payload);
      });
    })()
  );

export const requestForToken = async (registration) => {
  try {
    const messagingResolve = await messaging;
    const currentToken = await getToken(messagingResolve, {
      vapidKey:
        "BBhA0Z5VOrVpcOfVpB-WmMBii7MAb6FgW1a0rP5u7feSj3dkjNNoDHHJ_vSsDkRcerEfqfULurZFTNnR0n7UsEg",
      serviceWorkerRegistration: registration,
    });

    if (currentToken) {
      cookie.set("veerfcm", currentToken);
      onMessageListener();
    }
  } catch (err) {
    console.log("An error occurred while retrieving token. ", err);
  }
};
//   <ToastContainer position="top-right"
//   autoClose={5000}
//   hideProgressBar={false}
//   newestOnTop={false}
//   closeOnClick
//   rtl={false}
//   pauseOnFocusLoss
//   draggable
//   pauseOnHover />

// if ("serviceWorker" in navigator) {
//     navigator.serviceWorker.register(process.env.PUBLIC_URL + "/firebase-messaging-sw.js")
//     .then(function(registration) {
//       console.log("Registration successful, scope is:", registration);

//       getToken({messaging, vapidKey: "BM-khnvxAcAj9AjZ71MCRZHjcvZ0cNduKe4Dm5aBVpNYz0-iVf8JqFFiS8hJaV2CW8vjdjg39qJ8KapgWiFll7M",})
//       .then((currentToken) => {
//         if (currentToken) {
//           console.log('current token for client: ', currentToken);
//         } else {
//           console.log('No registration token available. Request permission to generate one.');
//         }
//       }).catch((err) => {
//         console.log('An error occurred while retrieving token. ', err);
//       });
//     })
//     .catch(function(err) {
//       console.log("Service worker registration failed, error:", err);
//     });
//     }
