import React, { useState, useEffect } from "react";
import { NavLink, useLocation, Link } from "react-router-dom";
import { useToggle } from "../../app/hooks";
import Logout from "../accounts/Logout";
import { getNotifications, getUnreadNotifications } from "../slice";
import { useDispatch } from "react-redux";
import Cookies from "universal-cookie";

const Sidebar = ({ isOpen: sidebarOpen }) => {
  const cookie = new Cookies();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const [subMenuActive, setSubMenuActive] = useState(false);
  const [cmsMenuActive, setCMSMenuActive] = useState(false);
  const [isOpen, toggle] = useToggle(false);
  const [counter, setCounter] = useState(1);

  useEffect(() => {
    dispatch(getNotifications({ page: 1 }));
    dispatch(getUnreadNotifications({}));
  }, [counter]);

  const handleRemoveCookies = () => {
    setCounter(counter + 1);
    cookie.remove("search");
    cookie.remove("age_to");
    cookie.remove("age_from");
    cookie.remove("have_father");
    cookie.remove("have_mother");
    cookie.remove("have_brother");
    cookie.remove("have_sister");
    cookie.remove("complexion");
    cookie.remove("ready_to_abroad");
    cookie.remove("own_house_occupation");
    cookie.remove("user_merital_status");
    cookie.remove("employment_type");
    cookie.remove("class");
    cookie.remove("occupation");
    cookie.remove("user_manglik_status");
    cookie.remove("occupation_country");
    cookie.remove("occupation_state");
    cookie.remove("occupation_city");
    cookie.remove("user_country");
    cookie.remove("user_state");
    cookie.remove("user_city");
    cookie.remove("heighest_education");
    cookie.remove("stream");
    cookie.remove("specialization");
    cookie.remove("user_height_from");
    cookie.remove("user_height_to");
  };
  return (
    <div className={sidebarOpen ? "m-side-bar open" : "m-side-bar"}>
      <div className="user-logo">
        <div className="logo-inner">
          <img src="images/white_logo.png" alt="logo" className="img-fluid" />
        </div>
      </div>
      <div className="menu-bar">
        <ul>
          <li>
            <NavLink to="/dashboard" className="">
              <img
                src="images/transactionwhite.png"
                alt="subscription"
                className="menu_icon1"
              />
              <img
                src="images/transactionblue.png"
                alt="subscription"
                className="menu_icon2"
              />
              <span onClick={handleRemoveCookies}>Dashboard</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/users/new"
              className={pathname.includes("/users") ? "active" : ""}
            >
              <img
                src="images/Manage-Users-white.png"
                alt="user"
                className="menu_icon1"
              />
              <img src="images/about.png" alt="user" className="menu_icon2" />
              <span className="m_manage" onClick={handleRemoveCookies}>
                Manage Users
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/approve-photos"
              className={pathname.includes("/approve-photos") ? "active" : ""}
            >
              <img
                src="images/Manage-Users-white.png"
                alt="user"
                className="menu_icon1"
              />
              <img src="images/about.png" alt="user" className="menu_icon2" />
              <span className="m_manage" onClick={handleRemoveCookies}>
                Manage Users Images
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/deactive-users" className="">
              <img
                src="images/Deactivated-users-white.png"
                alt="package"
                className="menu_icon1"
              />
              <img
                src="images/Deactivated-Users.png"
                alt="package"
                className="menu_icon2"
              />
              <span className="m_manage" onClick={handleRemoveCookies}>
                Deactivated Users
              </span>
            </NavLink>
          </li>
          {/* <li>
            <NavLink to="/user-package" className="">
              <img
                src="images/User-Package-white.png"
                alt="package"
                className="menu_icon1"
              />
              <img
                src="images/User-Package.png"
                alt="package"
                className="menu_icon2"
              />
              <span className="m_manage"> User Package</span>
            </NavLink>
          </li> */}

          <li>
            <NavLink to="/subscription" className="">
              <img
                src="images/Subscription-white.png"
                alt="subscription"
                className="menu_icon1"
              />
              <img
                src="images/Subscription.png"
                alt="subscription"
                className="menu_icon2"
              />
              <span onClick={handleRemoveCookies}>Manage Subscription</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/transaction" className="">
              <img
                src="images/transactionwhite.png"
                alt="subscription"
                className="menu_icon1"
              />
              <img
                src="images/transactionblue.png"
                alt="subscription"
                className="menu_icon2"
              />
              <span onClick={handleRemoveCookies}>Manage Transactions</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/revenue" className="">
              <img
                src="images/User-Package-white.png"
                alt="package"
                className="menu_icon1"
              />
              <img
                src="images/User-Package.png"
                alt="package"
                className="menu_icon2"
              />
              <span className="m_manage" onClick={handleRemoveCookies}>
                {" "}
                Manage Earnings
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/offers" className="">
              <img
                src="images/Subscription-white.png"
                alt="subscription"
                className="menu_icon1"
              />
              <img
                src="images/Subscription.png"
                alt="subscription"
                className="menu_icon2"
              />
              <span onClick={handleRemoveCookies}>Manage Offers</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/notifications" className="">
              <img
                src="images/Notificationinactive.png"
                alt="limit"
                className="menu_icon1"
              />
              <img
                src="images/NotificationActive.png"
                alt="limit"
                className="menu_icon2"
              />
              <span className="m_manage" onClick={handleRemoveCookies}>
                Manage Notifications
              </span>
            </NavLink>
          </li>
          {/* <li>
            <NavLink to="/user-limits" className="">
              <img
                src="images/User-limits-white.png"
                alt="limit"
                className="menu_icon1"
              />
              <img
                src="images/User-limits.png"
                alt="limit"
                className="menu_icon2"
              />
              <span className="m_manage">User limits</span>
            </NavLink>
          </li> */}
          {/* <li>
            <NavLink to="/support-form" className="">
              <img
                src="images/User-limits-white.png"
                alt="limit"
                className="menu_icon1"
              />
              <img
                src="images/User-limits.png"
                alt="limit"
                className="menu_icon2"
              />
              <span className="m_manage">Manage Support Form</span>
            </NavLink>
          </li> */}
          <li>
            <NavLink
              to="/report-revenue"
              className={
                pathname.includes(
                  "/report/subscribed-users" || "/report-revenue"
                )
                  ? "active"
                  : ""
              }
            >
              <img
                src="images/User-limits-white.png"
                alt="limit"
                className="menu_icon1"
              />
              <img
                src="images/User-limits.png"
                alt="limit"
                className="menu_icon2"
              />
              <span className="m_manage" onClick={handleRemoveCookies}>
                Manage Report
              </span>
            </NavLink>
          </li>

          <li>
            <NavLink
              to="/content-management"
              className={
                pathname.includes("/content-management") || cmsMenuActive
                  ? "sub_btn active"
                  : "sub_btn"
              }
              onClick={(event) => {
                event.preventDefault();
                setCMSMenuActive(!cmsMenuActive);
              }}
            >
              <img
                src="images/tnc-white.png"
                alt="settings"
                className="menu_icon1"
              />
              <img src="images/tnc.png" alt="settings" className="menu_icon2" />
              <span className="m_manage" onClick={handleRemoveCookies}>
                Content Management
              </span>
            </NavLink>
            <div
              className="sub-menu"
              style={{
                display: cmsMenuActive ? "block" : "none",
                background: "transparent",
              }}
            >
              <div className="c_pwd" style={{ border: "none" }}>
                <NavLink
                  to="/content-management/app-guide"
                  className="chang_pwd"
                >
                  App Guide
                </NavLink>
                <img src="images/tnc.png" alt="pwd" className="lock-icon" />
              </div>
              <div className="c_pwd cms" style={{ border: "none" }}>
                <NavLink
                  to="/content-management/about-us"
                  className="chang_pwd"
                >
                  About Us
                </NavLink>
                <img src="images/about.png" alt="pwd" className="lock-icon" />
              </div>
              <div className="c_pwd" style={{ border: "none" }}>
                <NavLink to="/content-management/banners" className="chang_pwd">
                  Banners
                </NavLink>
                <img src="images/tnc.png" alt="pwd" className="lock-icon" />
              </div>
              <div className="c_pwd" style={{ border: "none" }}>
                <NavLink to="/content-management/faqs" className="chang_pwd">
                  FAQs
                </NavLink>
                <img src="images/faqs.png" alt="pwd" className="lock-icon" />
              </div>
              <div className="c_pwd" style={{ border: "none" }}>
                <NavLink
                  to="/content-management/terms-conditions"
                  className="chang_pwd"
                >
                  Terms & Conditions
                </NavLink>
                <img src="images/tnc.png" alt="pwd" className="lock-icon" />
              </div>
              <div className="c_pwd cms" style={{ border: "none" }}>
                <NavLink
                  to="/content-management/success-story"
                  className="chang_pwd"
                >
                  Success Story
                </NavLink>
                <img src="images/about.png" alt="pwd" className="lock-icon" />
              </div>
              <div className="c_pwd cms" style={{ border: "none" }}>
                <NavLink
                  to="/content-management/contact-us"
                  className="chang_pwd"
                >
                  Contact US
                </NavLink>
                <img src="images/about.png" alt="pwd" className="lock-icon" />
              </div>

              <div className="c_pwd" style={{ border: "none" }}>
                <NavLink
                  to="/content-management/privacy-policy"
                  className="chang_pwd"
                >
                  Privacy Policy
                </NavLink>
                <img src="images/tnc.png" alt="pwd" className="lock-icon" />
              </div>
            </div>
          </li>
          <li>
            <NavLink
              to="/settings"
              className={
                pathname.includes("/settings") || subMenuActive
                  ? "sub_btn active"
                  : "sub_btn"
              }
              onClick={(event) => {
                event.preventDefault();
                setSubMenuActive(!subMenuActive);
              }}
            >
              <img
                src="images/Settings-white.png"
                alt="settings"
                className="menu_icon1"
              />
              <img
                src="images/Settings.png"
                alt="settings"
                className="menu_icon2"
              />
              <span className="m_manage" onClick={handleRemoveCookies}>
                Others
              </span>
            </NavLink>
            <div
              className="sub-menu"
              style={{
                display: subMenuActive ? "block" : "none",
                background: "transparent",
              }}
            >
              <div className="c_pwd" style={{ border: "none" }}>
                <NavLink to="/others/course" className="chang_pwd">
                  Course
                </NavLink>
              </div>
              <div className="c_pwd" style={{ border: "none" }}>
                {/* <img src="images/logout.png" alt="pwd" className="lock-icon" />{" "} */}
                <Link to="/others/specialization" className="chang_pwd">
                  Specialization
                </Link>
              </div>
              <div className="c_pwd" style={{ border: "none" }}>
                {/* <img src="images/logout.png" alt="pwd" className="lock-icon" />{" "} */}
                <Link to="/others/gothra" className="chang_pwd">
                  Gothra
                </Link>
              </div>
              <div className="c_pwd" style={{ border: "none" }}>
                {/* <img src="images/logout.png" alt="pwd" className="lock-icon" />{" "} */}
                <Link to="/others/devak" className="chang_pwd">
                  Devak
                </Link>
              </div>
              <div className="c_pwd" style={{ border: "none" }}>
                {/* <img src="images/logout.png" alt="pwd" className="lock-icon" />{" "} */}
                <Link to="/others/occupation" className="chang_pwd">
                  Occupation
                </Link>
              </div>
              <div className="c_pwd" style={{ border: "none" }}>
                {/* <img src="images/logout.png" alt="pwd" className="lock-icon" />{" "} */}
                <Link to="/others/parentlimit" className="chang_pwd">
                  Parents View Count
                </Link>
              </div>
            </div>
          </li>

          <li>
            <NavLink
              to="/user-queries"
              className={pathname.includes("/user-queries") ? "active" : ""}
            >
              <img
                src="images/User-limits-white.png"
                alt="limit"
                className="menu_icon1"
              />
              <img
                src="images/User-limits.png"
                alt="limit"
                className="menu_icon2"
              />
              <span className="m_manage" onClick={handleRemoveCookies}>
                User Queries
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/settings"
              className={
                pathname.includes("/settings") || subMenuActive
                  ? "sub_btn active"
                  : "sub_btn"
              }
              onClick={(event) => {
                event.preventDefault();
                setSubMenuActive(!subMenuActive);
              }}
            >
              <img
                src="images/Settings-white.png"
                alt="settings"
                className="menu_icon1"
              />
              <img
                src="images/Settings.png"
                alt="settings"
                className="menu_icon2"
              />
              <span className="m_manage" onClick={handleRemoveCookies}>
                Settings
              </span>
            </NavLink>
            <div
              className="sub-menu"
              style={{
                display: subMenuActive ? "block" : "none",
                background: "transparent",
              }}
            >
              <div className="c_pwd" style={{ border: "none" }}>
                <NavLink to="/settings/change-password" className="chang_pwd">
                  Change Password
                </NavLink>
                {/* <img
                  src="images/Change-Password.png"
                  alt="pwd"
                  className="lock-icon"
                /> */}
              </div>
              <div className="c_pwd" style={{ border: "none" }}>
                {/* <img src="images/logout.png" alt="pwd" className="lock-icon" />{" "} */}
                <Link
                  to="/login"
                  className="chang_pwd"
                  onClick={(event) => {
                    event.preventDefault();
                    toggle();
                  }}
                >
                  Logout
                </Link>
              </div>
            </div>
          </li>
        </ul>
      </div>
      {isOpen && <Logout isOpen={isOpen} toggle={toggle} />}
    </div>
  );
};

export default Sidebar;
