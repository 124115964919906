import React, { useEffect, useState } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { editOccupationDetails } from "../../../api/edit";
// import { useAddressPredictions } from "../../../app/hooks";
import { getUserDetails } from "../../slice";
import SimpleBackdrop from "../../../components/backdrop";
import countryProvinces from "../../../utils/countryProvinces.json";
import { Link } from "react-router-dom";
import { getHigherEducation } from "../../slice";
import { getCourseList } from "../../slice";
import { getSpecializationList } from "../../slice";
import { getEmployment } from "../../slice";
import { getClassList } from "../../slice";
import UserProfile from "../details/Profile";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { truncate } from "lodash";

const currentYear = new Date().getFullYear();

const OccupationDetails = () => {
  const dispatch = useDispatch();
  const { userID } = useParams();
  const { pathname } = useLocation();
  const { goBack } = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [value, setValue] = useState(null);
  const data = useSelector((state) => state.userDetails);
  const status = useSelector((state) => state?.status);

  const [saving, setSaving] = useState(false);
  const [address, setAddress] = useState(
    data?.occupationsDetails?.work_location || ""
  );
  const [stateProvinces, setStateProvinces] = useState([]);
  const highereducation = useSelector((state) => state?.higherEducation);
  const courses = useSelector((state) => state?.courseList);
  const specialization = useSelector((state) => state.specializationList);
  const occupation = useSelector((state) => state.employmentList);
  const classlist = useSelector((state) => state.classList);
  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null,
  });

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const ll = await getLatLng(results[0]);
    setAddress(value);
    setCoordinates(ll);
  };
  useEffect(
    () =>
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      }),
    []
  );
  useEffect(() => {
    dispatch(getHigherEducation());
    dispatch(getClassList());
  }, []);

  useEffect(() => {
    if (userID) {
      dispatch(getUserDetails({ user_id: userID }));
      dispatch(getEmployment());
      dispatch(
        getCourseList({
          higher_education: data?.occupationsDetails?.highest_education,
        })
      );
      dispatch(
        getSpecializationList({ course: data?.occupationsDetails?.stream })
      );
    }
  }, [dispatch, userID]);

  const formik = useFormik({
    initialValues: {
      highest_education: data?.occupationsDetails?.highest_education || "",
      stream: data?.occupationsDetails?.stream || "",
      specialization: data?.occupationsDetails?.specialization || "",
      employment_type: data?.occupationsDetails?.employment_type || "",
      classs: data?.occupationsDetails?.class || "",
      occupation: data?.occupationsDetails?.occupation || "",
      annual_income: data?.occupationsDetails?.annual_income || "",
      work_location: data?.occupationsDetails?.work_location || "",
      do_you_own_house: data?.occupationsDetails?.do_you_own_house || "",
      country: data?.occupationsDetails?.country || "",
      state: data?.occupationsDetails?.state || "",
      district: data?.occupationsDetails?.district || "",
      city: data?.occupationsDetails?.city || "",
      plan_to_settle_abroad:
        data?.occupationsDetails?.plan_to_settle_abroad || "",
      abroad_country: data?.occupationsDetails?.abroad_country || "",
      any: data?.occupationsDetails?.abroad_country === "" ? true : false,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      highest_education: Yup.string().required("*Required"),
      stream: Yup.string().required("*Required"),
      specialization: Yup.string().required("*Required"),
      employment_type: Yup.string().required("*Required"),
      occupation: Yup.string().required("*Required"),
      annual_income: Yup.string().required("*Required"),
    }),
    onSubmit: (values, { resetForm }) => {
      setSaving(true);
      const {
        highest_education,
        stream,
        specialization,
        employment_type,
        classs,
        occupation,
        annual_income,
        do_you_own_house,
        country,
        state,
        city,
        plan_to_settle_abroad,
        abroad_country,
        any,
      } = values;
      let payload = {
        userid: userID,
        highest_education: highest_education,
        stream: stream,
        specialization: specialization,
        employment_type: employment_type,
        class: classs,
        occupation: occupation,
        annual_income: annual_income,
        work_location: address,
        do_you_own_house: do_you_own_house,
        country: country,
        state: state,
        district: "",
        city: city,
        plan_to_settle_abroad: plan_to_settle_abroad,
        abroad_country: any === true ? "" : abroad_country,
      };

      editOccupationDetails(payload).then((response) => {
        setSaving(false);
        enqueueSnackbar(response?.message);
        if (response?.error_code === 0) {
          dispatch(getUserDetails({ user_id: userID }));
          // resetForm({});
          // goBack();
        }
      });
    },
  });
  const handleChangeHigher = (e) => {
    formik.setFieldValue("highest_education", e.target.value);
    dispatch(getCourseList({ higher_education: e.target.value }));
  };
  const handleChangeCourse = (e) => {
    formik.setFieldValue("stream", e.target.value);
    dispatch(getSpecializationList({ course: e.target.value }));
  };
  const handleChangeEmpolyment = (e) => {
    formik.setFieldValue("employment_type", e.target.value);
    // dispatch(getEmployment({ employment_type: e.target.value }));
  };
  useEffect(() => {
    const country = formik.values.country;
    if (country) {
      const provinces = countryProvinces.find(
        (el) => el.name === country
      )?.stateProvinces;
      setStateProvinces(provinces);
    }
  }, [formik.values.country]);

  return (
    <div className="m-main-content">
      <div className="manage_new_user_details p-5">
        <div
          className="new_user_detail_heading"
          style={{ position: "relative" }}
        >
          <button className="back-arrow" onClick={goBack}>
            <img src="images/back.png" alt="back" />
          </button>
          <h2 className="new_user_txt">
            {pathname.includes("/edit")
              ? "Edit User Professional Details"
              : "Add User Professional Details"}
          </h2>
          <UserProfile data={data} />
          <div className="edit-span-button">
            <span className="position-relative edit-icon-list">
              <img src="images/edit.png" className="action-view" alt="edit" />
              <div className="edit-pages">
                <Link to={`/users/edit/${userID}`}>Basic Details</Link>
                {/* <Link to={`/users/edit-gender/${userID}`}>Gender</Link> */}
                <Link to={`/users/edit-marital-status/${userID}`}>
                  Personal Details
                </Link>
                <Link to={`/users/edit-religion/${userID}`}>
                  Religion Details
                </Link>
                <Link to={`/users/body-details/${userID}`}>Body Details</Link>
                <Link to={`/users/edit-occupation-details/${userID}`}>
                  Professional Details
                </Link>
                <Link to={`/users/edit-family-details/${userID}`}>
                  Family Details
                </Link>
                <Link to={`/users/edit-lifestyle/${userID}`}>Lifestyle</Link>
                <Link to={`/users/edit-hobbies/${userID}`}>Hobbies</Link>
                <Link to={`/users/edit-horoscope-details/${userID}`}>
                  Horoscope Details
                </Link>
                <Link to={`/users/edit-partner-preferences/${userID}`}>
                  My Partner Preferences
                </Link>
                <Link to={`/users/about-me/${userID}`}>About Me</Link>
                <Link to={`/users/my-document/${userID}`}>My Document</Link>
              </div>
            </span>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
              <form className="row form-style" onSubmit={formik.handleSubmit}>
                <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                  <label className="num">Highest Qualification</label>
                  <div className="input-container add_user_info">
                    <select
                      className="form-control ps-4"
                      // {...formik.getFieldProps("highest_education")}
                      value={formik.values.highest_education}
                      onChange={(e) => {
                        handleChangeHigher(e);
                        // handleCourse(e);
                      }}
                    >
                      <option disabled value="">
                        Select Highest Education
                      </option>
                      {highereducation?.map((res, i) => {
                        return (
                          <option value={res?._id} key={i}>
                            {res?.name}
                          </option>
                        );
                      })}
                    </select>
                    <img
                      src="images/suitcase.png"
                      className="input-img"
                      alt=""
                    />
                    {formik.touched.highest_education &&
                    formik.errors.highest_education ? (
                      <span className="error">
                        {formik.errors.highest_education}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                  <label className="num">Course</label>
                  <div className="input-container add_user_info">
                    <select
                      className="form-control ps-4"
                      // {...formik.getFieldProps("highest_education")}
                      value={formik.values.stream}
                      onChange={(e) => {
                        handleChangeCourse(e);
                      }}
                      disabled={!courses?.length}
                    >
                      <option>Select Course</option>
                      {courses?.map((res, i) => {
                        return (
                          <option value={res?._id} key={i}>
                            {res?.name}
                          </option>
                        );
                      })}
                    </select>
                    <img
                      src="images/suitcase.png"
                      className="input-img"
                      alt=""
                    />
                    {formik.touched.stream && formik.errors.stream ? (
                      <span className="error">{formik.errors.stream}</span>
                    ) : null}
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                  <label className="num">Specialization</label>
                  <div className="input-container add_user_info">
                    <select
                      className="form-control ps-4"
                      {...formik.getFieldProps("specialization")}
                      disabled={!specialization?.length}
                    >
                      <option>Select Specialization</option>
                      {specialization?.map((res, i) => {
                        return (
                          <option value={res?._id} key={i}>
                            {res?.name}
                          </option>
                        );
                      })}
                    </select>
                    <img
                      src="images/suitcase.png"
                      className="input-img"
                      alt=""
                    />
                    {formik.touched.specialization &&
                    formik.errors.specialization ? (
                      <span className="error">
                        {formik.errors.specialization}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                  <label className="num">Employment</label>
                  <div className="input-container add_user_info">
                    <select
                      className="form-control ps-4"
                      // {...formik.getFieldProps("employment_type")}
                      value={formik.values.employment_type}
                      onChange={(e) => {
                        handleChangeEmpolyment(e);
                      }}
                    >
                      <option disabled value="">
                        Select Employment
                      </option>
                      <option value="Government">Government</option>
                      <option value="Private">Private</option>
                      <option value="Business">Business</option>
                      <option value="Defence">Defence</option>
                      <option value="Self employed">Self employed</option>
                      <option value="Private Service & Business">
                        Private Service & Business
                      </option>
                      <option value="Government Service & Business">
                        Government Service & Business
                      </option>
                      <option value="Self Employed & Business">
                        Self Employed & Business
                      </option>
                    </select>
                    <img
                      src="images/Path 17786.png"
                      className="input-img pass"
                      alt=""
                    />
                    {formik.touched.employment_type &&
                    formik.errors.employment_type ? (
                      <span className="error">
                        {formik.errors.employment_type}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                  <label className="num">Occupation</label>

                  <div className="input-container add_user_info">
                    <select
                      className="form-control ps-4"
                      {...formik.getFieldProps("occupation")}
                      disabled={!occupation?.length}
                    >
                      <option disabled>Select Occupation</option>
                      {occupation?.map((res, i) => {
                        return (
                          <option value={res?._id} key={i}>
                            {res?.name}
                          </option>
                        );
                      })}
                    </select>
                    <img
                      src="images/Department.png"
                      className="input-img pass"
                      alt=""
                    />
                    {formik.touched.occupation && formik.errors.occupation ? (
                      <span className="error">{formik.errors.occupation}</span>
                    ) : null}
                  </div>
                </div>
                {formik.values.employment_type === "Government" ||
                formik.values.employment_type === "Defence" ? (
                  <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                    <label className="num">Class</label>
                    <div className="input-container add_user_info">
                      <select
                        className="form-control ps-4"
                        {...formik.getFieldProps("classs")}
                      >
                        <option disabled value="">
                          Select Class
                        </option>
                        {classlist?.map((res, i) => {
                          return <option value={res?._id}>{res?.name}</option>;
                        })}
                      </select>
                      <img
                        src="images/suitcase.png"
                        className="input-img pass"
                        alt=""
                      />
                      {formik.touched.classs && formik.errors.classs ? (
                        <span className="error">{formik.errors.classs}</span>
                      ) : null}
                    </div>
                  </div>
                ) : (
                  <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4"></div>
                )}

                <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                  <label className="num">Annual Income</label>
                  <div className="input-container add_user_info">
                    <input
                      type="text"
                      className="form-control ps-4"
                      placeholder="Annual Income"
                      {...formik.getFieldProps("annual_income")}
                    />
                    <img
                      src="images/Department.png"
                      className="input-img"
                      alt=""
                    />
                    {formik.touched.annual_income &&
                    formik.errors.annual_income ? (
                      <span className="error">
                        {formik.errors.annual_income}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                  <label className="num">Work Location</label>
                  <div className="input-container add_user_info">
                    {/* <input
                      type="text"
                      className="form-control ps-4"
                      placeholder="Work Location"
                      {...formik.getFieldProps("work_location")}
                    /> */}
                    <PlacesAutocomplete
                      value={address}
                      onChange={setAddress}
                      onSelect={handleSelect}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <img
                            src="assets/img/searchIcon.png"
                            className="input-img"
                            alt=""
                          />
                          <input
                            {...getInputProps({
                              placeholder: "Search Your Location",
                              className:
                                "location-search-input form-control ps-5 ",
                            })}
                          />
                          <div className="autocomplete-dropdown-container my-3">
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                              const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? {
                                    backgroundColor: "lightgray",
                                    cursor: "pointer",
                                  }
                                : {
                                    backgroundColor: "#ffffff",
                                    cursor: "pointer",
                                  };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                    <img
                      src="images/address.png"
                      className="input-img pass"
                      alt=""
                    />
                  </div>
                </div>

                <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-5 mt-5">
                  <div className="form-groupp">
                    <div className="nutritionRadio">
                      <label className="text_label">
                        Do you own a house at this place ?
                      </label>
                      <p className="mb-0">
                        <input
                          type="radio"
                          id="test1"
                          name="radio-group"
                          checked={formik.values.do_you_own_house == true}
                          onChange={() =>
                            formik.setFieldValue("do_you_own_house", true)
                          }
                        />
                        <label for="test1">Yes</label>
                      </p>
                      <p className="mb-0">
                        <input
                          type="radio"
                          id="test2"
                          name="radio-group"
                          checked={formik.values.do_you_own_house == false}
                          onChange={() =>
                            formik.setFieldValue("do_you_own_house", false)
                          }
                        />
                        <label for="test2">No</label>
                      </p>
                      {formik.touched.do_you_own_house &&
                      formik.errors.do_you_own_house ? (
                        <span className="error">
                          {formik.errors.do_you_own_house}
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-2"></div>
                <>
                  <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-5">
                    <div className="input-container add_user_info">
                      <select
                        className="form-control ps-4"
                        onChange={(event) => {
                          formik.setFieldValue("country", event.target.value);
                          formik.setFieldValue("state", "");
                        }}
                        value={formik.values.country || ""}
                      >
                        <option value="" disabled>
                          Select Your Country
                        </option>
                        {countryProvinces?.length
                          ? countryProvinces.map((el) => (
                              <option value={el.name} key={el.name}>
                                {el.name}
                              </option>
                            ))
                          : null}
                      </select>
                      <img
                        src="images/Address.png"
                        className="input-img pass"
                        alt=""
                      />
                      {formik.touched.country && formik.errors.country ? (
                        <span className="error">{formik.errors.country}</span>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-5">
                    <div className="input-container add_user_info">
                      <select
                        className="form-control ps-4"
                        {...formik.getFieldProps("state")}
                        disabled={!stateProvinces?.length}
                        value={formik.values.state || ""}
                      >
                        <option value="" disabled>
                          Select Your State
                        </option>
                        {stateProvinces?.length
                          ? stateProvinces.map((el) => (
                              <option value={el.name} key={el.name}>
                                {el.name}
                              </option>
                            ))
                          : null}
                      </select>
                      <img
                        src="images/Address.png"
                        className="input-img pass"
                        alt=""
                      />
                      {formik.touched.state && formik.errors.state ? (
                        <span className="error">{formik.errors.state}</span>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-5">
                    <label className="num">City</label>

                    <div className="input-container add_user_info">
                      <input
                        type="text"
                        className="form-control ps-4"
                        placeholder="City"
                        {...formik.getFieldProps("city")}
                      />
                      <img
                        src="images/Address.png"
                        className="input-img pass"
                        alt=""
                      />
                      {formik.touched.city && formik.errors.city ? (
                        <span className="error">{formik.errors.city}</span>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-2"></div>
                </>
                <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-5">
                  <div className="form-groupp">
                    <div className="nutritionRadio">
                      <label className="text_label">
                        Plan/ready to settle in abroad ?
                      </label>
                      <p className="mb-0">
                        <input
                          type="radio"
                          id="test11"
                          name="radio-group1"
                          onChange={() =>
                            formik.setFieldValue("plan_to_settle_abroad", true)
                          }
                          checked={formik.values.plan_to_settle_abroad == true}
                        />
                        <label for="test11">Yes</label>
                      </p>
                      <p className="mb-0">
                        <input
                          type="radio"
                          id="test22"
                          name="radio-group1"
                          onChange={() =>
                            formik.setFieldValue("plan_to_settle_abroad", false)
                          }
                          checked={formik.values.plan_to_settle_abroad == false}
                        />
                        <label for="test22">No</label>
                      </p>
                      {formik.touched.plan_to_settle_abroad &&
                      formik.errors.plan_to_settle_abroad ? (
                        <span className="error">
                          {formik.errors.plan_to_settle_abroad}
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-5"></div>
                <div className="form-group col-sm-12 col-md-3 col-lg-3 mb-5">
                  <div className="form-groupp">
                    <div className="nutritionRadio">
                      <label className="text_label">Any </label>
                      <p className="mb-0 mx-3">
                        <input
                          type="checkbox"
                          id="test11"
                          name="any"
                          checked={formik.values.any === true}
                          onChange={() =>
                            formik.setFieldValue("any", !formik.values.any)
                          }
                        />
                      </p>
                    </div>
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-3 col-lg-3 mb-5"></div>
                {formik.values.any === false ? (
                  <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-5">
                    <div className="input-container add_user_info">
                      <select
                        className="form-control ps-4"
                        onChange={(event) => {
                          formik.setFieldValue(
                            "abroad_country",
                            event.target.value
                          );
                          // formik.setFieldValue("state", "");
                        }}
                        value={formik.values.abroad_country || ""}
                      >
                        <option value="" disabled>
                          Select Your Country
                        </option>
                        {countryProvinces?.length
                          ? countryProvinces.map((el) => (
                              <option value={el.name} key={el.name}>
                                {el.name}
                              </option>
                            ))
                          : null}
                      </select>
                      <img
                        src="images/Address.png"
                        className="input-img pass"
                        alt=""
                      />
                      {formik.touched.country && formik.errors.country ? (
                        <span className="error">{formik.errors.country}</span>
                      ) : null}
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div className="form-group col-sm-12 col-md-12 col-lg-12 text-center">
                  <div className="button_area">
                    <button
                      type="submit"
                      className="save_btn"
                      disabled={saving}
                    >
                      {saving ? (
                        <CircularProgress color="inherit" />
                      ) : pathname.includes("/edit") ? (
                        "Save Changes"
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {status === "loading" && <SimpleBackdrop open={status === "loading"} />}
    </div>
  );
};

export default OccupationDetails;
