import React, { useEffect, useState } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import countryProvinces from "../../../utils/countryProvinces.json";
import SimpleBackdrop from "../../../components/backdrop";
import { editFamilyDetails } from "../../../api/edit";
import { getUserDetails } from "../../slice";
import { Link } from "react-router-dom";
import UserProfile from "../details/Profile";
import { getUserFamilyIncome } from "../../slice";

const FamilyDetails = () => {
  const dispatch = useDispatch();
  const { goBack } = useHistory();
  const { userID } = useParams();
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const data = useSelector((state) => state.userDetails);
  const incomedata = useSelector((state) => state.userFamilyIncomeDetails);

  const status = useSelector((state) => state?.status);

  const [saving, setSaving] = useState(false);
  const [stateProvinces, setStateProvinces] = useState([]);
  const [uploading, setUploading] = useState(false);

  useEffect(
    () =>
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      }),
    []
  );

  useEffect(() => {
    if (userID) {
      dispatch(getUserDetails({ user_id: userID }));
      dispatch(getUserFamilyIncome({ userid: userID }));
    }
  }, [dispatch, userID]);

  const formik = useFormik({
    initialValues: {
      family_type: data?.familiesDetails?.family_type || "",
      family_values: data?.familiesDetails?.family_values || "",
      family_status: data?.familiesDetails?.family_status || "",
      father_alive: data?.familiesDetails?.father_alive || "",
      mother_alive: data?.familiesDetails?.mother_alive || "",
      no_of_brother:
        data?.familiesDetails?.no_of_brother === 0
          ? 0
          : data?.familiesDetails?.no_of_brother || "",
      no_of_sister:
        data?.familiesDetails?.no_of_sister === 0
          ? 0
          : data?.familiesDetails?.no_of_sister || "",
      family_monthly_average_income:
        data?.familiesDetails?.family_monthly_average_income_from +
          "-" +
          data?.familiesDetails?.family_monthly_average_income_to || "",
      family_net_worth:
        data?.familiesDetails?.family_net_worth_from +
          "-" +
          data?.familiesDetails?.family_net_worth_to || "",
      number_of_acres_of_firm:
        data?.familiesDetails?.number_of_acres_of_firm_from +
          "-" +
          data?.familiesDetails?.number_of_acres_of_firm_to || "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      family_type: Yup.string().required("*Required"),
      family_values: Yup.string().required("*Required"),
      family_status: Yup.string().required("*Required"),
      father_alive: Yup.string().required("*Required"),
      mother_alive: Yup.string().required("*Required"),
      no_of_brother: Yup.number().min(0).max(10).required("*Required"),
      no_of_sister: Yup.number().min(0).max(10).required("*Required"),
      family_monthly_average_income: Yup.string().required("*Required"),
      family_net_worth: Yup.string().required("*Required"),
      number_of_acres_of_firm: Yup.string().required("*Required"),
    }),
    onSubmit: (values, { resetForm }) => {
      setSaving(true);
      let payload = {
        userid: userID,
        family_type: values?.family_type,
        family_values: values.family_values,
        family_status: values?.family_status,
        father_alive: values?.father_alive,
        mother_alive: values?.mother_alive,
        no_of_brother: values?.no_of_brother,
        no_of_sister: values?.no_of_sister,
        family_monthly_average_income_from:
          values.family_monthly_average_income.split("-")[0],
        family_monthly_average_income_to:
          values.family_monthly_average_income.split("-")[1],
        family_net_worth_from: values.family_net_worth.split("-")[0],
        family_net_worth_to: values.family_net_worth.split("-")[1],
        number_of_acres_of_firm_from:
          values.number_of_acres_of_firm.split("-")[0],
        number_of_acres_of_firm_to:
          values.number_of_acres_of_firm.split("-")[1],
      };

      editFamilyDetails(payload).then((response) => {
        setSaving(false);
        enqueueSnackbar(response?.message);
        if (response?.error_code === 0) {
          // resetForm({});
          // goBack();
          dispatch(getUserDetails({ user_id: userID }));
        }
      });
    },
  });

  useEffect(() => {
    const country = formik.values.country;
    if (country) {
      const provinces = countryProvinces.find(
        (el) => el.name === country
      )?.stateProvinces;
      setStateProvinces(provinces);
    }
  }, [formik.values.country]);

  return (
    <div className="m-main-content">
      <div className="manage_new_user_details p-5">
        <div
          className="new_user_detail_heading"
          style={{ position: "relative" }}
        >
          <button className="back-arrow" onClick={goBack}>
            <img src="images/back.png" alt="back" />
          </button>
          <h2 className="new_user_txt">
            {pathname.includes("/edit")
              ? "Edit User Family Details"
              : "Add User Family Details"}
          </h2>
          <UserProfile data={data} />
          <div className="edit-span-button">
            <span className="position-relative edit-icon-list">
              <img src="images/edit.png" className="action-view" alt="edit" />
              <div className="edit-pages">
                <Link to={`/users/edit/${userID}`}>Basic Details</Link>
                {/* <Link to={`/users/edit-gender/${userID}`}>Gender</Link> */}
                <Link to={`/users/edit-marital-status/${userID}`}>
                  Personal Details
                </Link>
                <Link to={`/users/edit-religion/${userID}`}>
                  Religion Details
                </Link>
                <Link to={`/users/body-details/${userID}`}>Body Details</Link>
                <Link to={`/users/edit-occupation-details/${userID}`}>
                  Professional Details
                </Link>
                <Link to={`/users/edit-family-details/${userID}`}>
                  Family Details
                </Link>
                <Link to={`/users/edit-lifestyle/${userID}`}>Lifestyle</Link>
                <Link to={`/users/edit-hobbies/${userID}`}>Hobbies</Link>
                <Link to={`/users/edit-horoscope-details/${userID}`}>
                  Horoscope Details
                </Link>
                <Link to={`/users/edit-partner-preferences/${userID}`}>
                  My Partner Preferences
                </Link>
                <Link to={`/users/about-me/${userID}`}>About Me</Link>
                <Link to={`/users/my-document/${userID}`}>My Document</Link>
              </div>
            </span>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
              <form className="row form-style" onSubmit={formik.handleSubmit}>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 graduation">
                  <div className="row element">
                    <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                      <label className="num">Family Type</label>
                      <div className="input-container add_user_info">
                        <select
                          className="form-control ps-4"
                          {...formik.getFieldProps("family_type")}
                        >
                          <option disabled value="">
                            Select Family Type
                          </option>
                          <option value="Joint">Joint</option>
                          <option value="Nuclear">Nuclear</option>
                        </select>
                        <img
                          src="images/Father-Name.png"
                          className="input-img"
                          alt=""
                        />
                        {formik.touched.family_type &&
                        formik.errors.family_type ? (
                          <span className="error">
                            {formik.errors.family_type}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                      <label className="num">Family Values</label>
                      <div className="input-container add_user_info">
                        <select
                          className="form-control ps-4"
                          {...formik.getFieldProps("family_values")}
                        >
                          <option disabled value="">
                            Select Family Value
                          </option>
                          <option value="Orthodox">Orthodox</option>
                          <option value="Traditional">Traditional</option>
                          <option value="Moderate">Moderate</option>
                          <option value="Liberal">Liberal</option>
                        </select>
                        <img
                          src="images/Father-Name.png"
                          className="input-img"
                          alt=""
                        />
                        {formik.touched.family_values &&
                        formik.errors.family_values ? (
                          <span className="error">
                            {formik.errors.family_values}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                      <label className="num">Family Status</label>
                      <div className="input-container add_user_info">
                        <select
                          className="form-control ps-4"
                          {...formik.getFieldProps("family_status")}
                        >
                          <option disabled value="">
                            Select Family Status
                          </option>
                          <option value="Middle class">Middle Class</option>
                          <option value="Upper middle class">
                            Upper Middle Class
                          </option>
                          <option value="Rich">Rich</option>
                        </select>
                        <img
                          src="images/Father-Name.png"
                          className="input-img"
                          alt=""
                        />
                        {formik.touched.family_status &&
                        formik.errors.family_status ? (
                          <span className="error">
                            {formik.errors.family_status}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="row element">
                    <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                      <label className="num">Father Alive</label>
                      <div className="input-container add_user_info">
                        <select
                          className="form-control ps-4"
                          {...formik.getFieldProps("father_alive")}
                        >
                          <option disabled value="">
                            Select
                          </option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                        <img
                          src="images/Father-Name.png"
                          className="input-img"
                          alt=""
                        />
                        {formik.touched.father_alive &&
                        formik.errors.father_alive ? (
                          <span className="error">
                            {formik.errors.father_alive}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                      <label className="num">Mother Alive</label>
                      <div className="input-container add_user_info">
                        <select
                          className="form-control ps-4"
                          {...formik.getFieldProps("mother_alive")}
                        >
                          <option disabled value="">
                            Select
                          </option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                        <img
                          src="images/Mother-Name.png"
                          className="input-img"
                          alt=""
                        />
                        {formik.touched.mother_alive &&
                        formik.errors.mother_alive ? (
                          <span className="error">
                            {formik.errors.mother_alive}
                          </span>
                        ) : null}
                      </div>
                    </div>

                    <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                      <label className="num">No. Of Brother</label>
                      <div className="input-container add_user_info">
                        <input
                          type="number"
                          className="form-control ps-4"
                          placeholder="No' of brother"
                          {...formik.getFieldProps("no_of_brother")}
                        />
                        <img
                          src="images/Father-name.png"
                          className="input-img pass"
                          alt=""
                        />
                        {formik.touched.no_of_brother &&
                        formik.errors.no_of_brother ? (
                          <span className="error">
                            {formik.errors.no_of_brother}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                      <label className="num">No. Of Sister</label>
                      <div className="input-container add_user_info">
                        <input
                          type="number"
                          className="form-control ps-4"
                          placeholder="No' of sister"
                          {...formik.getFieldProps("no_of_sister")}
                        />
                        <img
                          src="images/Mother-name.png"
                          className="input-img pass"
                          alt=""
                        />
                        {formik.touched.no_of_sister &&
                        formik.errors.no_of_sister ? (
                          <span className="error">
                            {formik.errors.no_of_sister}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="row element">
                    <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                      <label className="num">Family Monthly Income</label>
                      <div className="input-container add_user_info">
                        <select
                          className="form-control ps-4"
                          {...formik.getFieldProps(
                            "family_monthly_average_income"
                          )}
                        >
                          <option disabled value="">
                            Select
                          </option>
                          {incomedata?.income_list?.map((el, i) => {
                            return (
                              <option value={el?.value}>{el?.label}</option>
                            );
                          })}
                        </select>
                        <img
                          src="images/salary-black.png"
                          className="input-img"
                          alt=""
                        />
                        {formik.touched.family_monthly_average_income &&
                        formik.errors.family_monthly_average_income ? (
                          <span className="error">
                            {formik.errors.family_monthly_average_income}
                          </span>
                        ) : null}
                      </div>
                    </div>

                    <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                      <label className="num">
                        No. Of Acres Of number_of_acres_of_firm
                      </label>
                      <div className="input-container add_user_info">
                        {/* <input
                          type="number"
                          className="form-control ps-4"
                          placeholder="No of acres of number_of_acres_of_firm"
                          {...formik.getFieldProps("number_of_acres_of_firm")}
                        /> */}
                        <select
                          className="form-control ps-4"
                          {...formik.getFieldProps("number_of_acres_of_firm")}
                        >
                          <option disabled value="">
                            Select
                          </option>
                          {incomedata?.farm_list?.map((el, i) => {
                            return (
                              <option value={el?.value}>{el?.label}</option>
                            );
                          })}
                        </select>

                        <img
                          src="images/Department.png"
                          className="input-img pass"
                          alt=""
                        />
                        {formik.touched.number_of_acres_of_firm &&
                        formik.errors.number_of_acres_of_firm ? (
                          <span className="error">
                            {formik.errors.number_of_acres_of_firm}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="form-group col-sm-12 col-md-6 col-lg-6 mb-4">
                      <label className="num">Family Net Worth</label>
                      <div className="input-container add_user_info">
                        {/* <input
                          type="text"
                          className="form-control ps-4"
                          placeholder="Family net worth"
                          {...formik.getFieldProps("family_net_worth")}
                        /> */}
                        <select
                          className="form-control ps-4"
                          {...formik.getFieldProps("family_net_worth")}
                        >
                          <option disabled value="">
                            Select
                          </option>
                          {incomedata?.family_net_worth_list?.map((el, i) => {
                            return (
                              <option value={el?.value}>{el?.label}</option>
                            );
                          })}
                        </select>
                        <img
                          src="images/salary-black.png"
                          className="input-img pass"
                          alt=""
                        />
                        {formik.touched.family_net_worth &&
                        formik.errors.family_net_worth ? (
                          <span className="error">
                            {formik.errors.family_net_worth}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group col-sm-12 col-md-12 col-lg-12 text-center">
                  <div className="button_area">
                    <button
                      type="submit"
                      className="save_btn"
                      disabled={saving}
                    >
                      {saving ? (
                        <CircularProgress color="inherit" />
                      ) : pathname.includes("/edit") ? (
                        "Save Changes"
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {(uploading || status === "loading") && (
        <SimpleBackdrop open={uploading || status === "loading"} />
      )}
    </div>
  );
};

export default FamilyDetails;
