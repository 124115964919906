import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { Link } from "react-router-dom";

import { addUser, editParentsData, editUser } from "../../api/users";
import SimpleBackdrop from "../../components/backdrop";
import { fileUploader } from "../../api/accounts";
import { getUserDetails } from "../slice";

import countryProvinces from "../../utils/countryProvinces.json";
import { min18Years } from "../../utils";
import S3FileUpload from "react-s3";
import { values } from "lodash";
window.Buffer = window.Buffer || require("buffer").Buffer;

const regex =
  /\.(png|jpg|jpeg|pdf|PNG|JPG|JPEG|avif|apng|gif|jfif|pjpeg|pjp|svg|webp)$/;
const maxAge = min18Years();

const AddUser = () => {
  const dispatch = useDispatch();
  const { userID } = useParams();
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { goBack } = useHistory();

  const status = useSelector((state) => state.status);
  const data = useSelector((state) => state.userDetails);

  const [showPwd, setShowPwd] = useState(false);
  const [showConfPwd, setShowConfPwd] = useState(false);
  const [saving, setSaving] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [stateProvinces, setStateProvinces] = useState([]);

  useEffect(() => {
    if (userID) {
      dispatch(getUserDetails({ user_id: userID }));
    }
  }, [dispatch, userID]);
  const formik = useFormik({
    initialValues: {
      profile: data?.profile_type || "",
      gender: data?.gender || "",
      firstName: data?.first_name || "",
      middleName: data?.middle_name || "",
      lastName: data?.last_name || "",
      mobileNumber: data?.country_code ? data?.country_code + data?.mobile : "",
      email: data?.email || "",
      dob: data?.date_of_birth?.substr(0, 10),
      whatsappNumber: data?.whatsapp_country_code
        ? data?.whatsapp_country_code + data?.whatsapp_mobile_number
        : "",
      password: "",
      confirmPassword: "",
      image: data?.profile_image
        ? data.profile_image
        : data?.profile_image_video_path?.length
        ? data.profile_image_video_path.find(
            (path) =>
              typeof path === "string" && path?.toLowerCase()?.match(regex)
          )
        : "",
      parent_name: data?.parent_full_name || "",
      parent_number: data?.parent_country_code
        ? data?.parent_country_code + data?.parent_mobile
        : "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required(" *Required"),
      firstName: Yup.string().required(" *Required"),
      middleName: Yup.string().required(" *Required"),
      lastName: Yup.string().required(" *Required"),
      mobileNumber: Yup.string().required(" *Required"),
      whatsappNumber: Yup.string().required(" *Required"),
      dob: Yup.string().required(" *Required"),
      gender: Yup.string().required("*Required"),
      profile: Yup.string().required("*Required"),
      password: Yup.string()
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        )
        .when("editing", {
          is: false,
          then: Yup.string().required("Required"),
        }),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .when("editing", {
          is: false,
          then: Yup.string().required("Required"),
        }),
    }),
    onSubmit: (values, { resetForm }) => {
      if (validateMobile()) {
        setSaving(true);
        const {
          firstName,
          lastName,
          middleName,
          mobileNumber,
          whatsappNumber,
          email,
          password,
          image,
          dob,
          profile,
          gender,
        } = values;
        const countryCode = formatPhoneNumberIntl(mobileNumber).split(" ")[0];
        const countryCodeWhatsapp =
          formatPhoneNumberIntl(whatsappNumber).split(" ")[0];

        let payload = {
          profile_image: image,
          first_name: firstName,
          middle_name: middleName,
          last_name: lastName,
          country_code: countryCode,
          mobile_number: mobileNumber.replace(countryCode, ""),
          whatsapp_mobile_number: whatsappNumber.replace(countryCode, ""),
          whatsapp_country_code: countryCodeWhatsapp,
          email_address: email,
          date_of_birth: dob,
          profile_type: profile,
          gender: gender,
        };

        if (userID) {
          payload.user_id = userID;

          editUser(payload).then((response) => {
            enqueueSnackbar(response.message);
            setSaving(false);
            if (response?.error_code === 200) {
              resetForm({});
              goBack();
            }
          });
        } else {
          payload.password = password;

          addUser(payload).then((response) => {
            enqueueSnackbar(response.message);
            setSaving(false);
            if (response?.error_code === 200) {
              resetForm({});
              goBack();
            }
          });
        }
      }
    },
  });

  const handleAddEditParentsData = () => {
    const countryCode = formatPhoneNumberIntl(
      formik?.values?.parent_number
    ).split(" ")[0];

    let payload = {
      parent_country_code: countryCode,
      parent_full_name: formik?.values?.parent_name,
      parent_mobile: formik?.values?.parent_number?.replace(countryCode, ""),
    };
    if (userID) {
      payload.userid = userID;

      editParentsData(payload).then((response) => {
        enqueueSnackbar(response.message);
        setSaving(false);
        if (response?.error_code === 200) {
          goBack();
        }
      });
    } else {
      editParentsData(payload).then((response) => {
        enqueueSnackbar(response.message);
        setSaving(false);
        if (response?.error_code === 200) {
          goBack();
        }
      });
    }
  };
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const country = formik.values.country;
    if (country) {
      const provinces = countryProvinces.find(
        (el) => el.name === country
      )?.stateProvinces;
      setStateProvinces(provinces);
    }
  }, [formik.values.country]);

  const validateMobile = () => {
    if (!isValidPhoneNumber(formik.values.mobileNumber || "")) {
      formik.setFieldError("mobileNumber", "Inavalid");
      return false;
    }

    return true;
  };

  const handleImageUpload = (event) => {
    formik.setFieldError("image", "");
    const file = event.target.files[0];
    const fileType = file.type.split("/")[0];
    if (fileType !== "image") {
      formik.setFieldError("image", "Only image files are accepted");
    } else if (fileType === "image" && Math.floor(file.size / 1000000) > 5) {
      formik.setFieldError("image", "File size should not exceed 5 MB");
    } else {
      setUploading(true);
      let formData = new FormData();
      formData.append("file", file);
      fileUploader(formData).then(async (response) => {
        setUploading(false);
        if (response?.error_code === 0 && response?.data?.fileurl) {
          await formik.setFieldValue("image", response.data.fileurl);
        }
      });
    }
  };

  return (
    <div className="m-main-content">
      <div className="manage_new_user_details">
        <div className="container-fluid">
          <div className="row">
            <form className="col-md-12" onSubmit={formik.handleSubmit}>
              <div
                className="new_user_detail_heading"
                style={{ position: "relative" }}
              >
                <button className="back-arrow" onClick={goBack}>
                  <img src="images/back.png" alt="back" />
                </button>
                <h2 className="new_user_txt">
                  {pathname.includes("/edit")
                    ? "Edit User Details"
                    : "Add New User"}
                </h2>
                {/* <div className="edit-span-button">
                  <span className="position-relative edit-icon-list">
                    <img
                      src="images/edit.png"
                      className="action-view"
                      alt="edit"
                    />
                    <div className="edit-pages">
                      <Link to={`/users/edit/${userID}`}>Basic Details</Link>
                      <Link to={`/users/edit-marital-status/${userID}`}>
                        Personal Details
                      </Link>
                      <Link to={`/users/edit-religion/${userID}`}>
                        Religion Details
                      </Link>
                      <Link to={`/users/body-details/${userID}`}>Body Details</Link>
                      <Link to={`/users/edit-occupation-details/${userID}`}>
                        Professional Details
                      </Link>
                      <Link to={`/users/edit-family-details/${userID}`}>
                        Family Details
                      </Link>
                      <Link to={`/users/edit-lifestyle/${userID}`}>
                        Lifestyle
                      </Link>
                      <Link to={`/users/edit-hobbies/${userID}`}>Hobbies</Link>
                      <Link to={`/users/edit-horoscope-details/${userID}`}>
                        Horoscope Details
                      </Link>
                      <Link to={`/users/edit-partner-preferences/${userID}`}>
                        My Partner Preferences
                      </Link>
                      <Link to={`/users/about-me/${userID}`}>
                        About Me
                      </Link>
                      <Link to={`/users/about-me/${userID}`}>
                        My Document
                      </Link>
                    </div>
                  </span>
                </div> */}
              </div>
              <div className="user_img_main">
                <div className="user_img_inner">
                  {formik.touched.image && formik.errors.image ? (
                    <span className="error">{formik.errors.image}</span>
                  ) : null}
                  {!uploading && formik.values.image ? (
                    <img
                      className="usr-img"
                      src={
                        formik.values.image.preview
                          ? formik.values.image.preview
                          : formik.values.image
                      }
                      alt="Upload Icon"
                    />
                  ) : uploading ? (
                    <CircularProgress />
                  ) : (
                    <img
                      src="images/favicon.png"
                      alt="user"
                      className="usr-img"
                    />
                  )}

                  <input
                    type="file"
                    name=""
                    id="picImg"
                    onChange={handleImageUpload}
                  />
                  <label htmlFor="picImg" className="slt_pic">
                    <img src="images/Plus.png" alt="Add" />
                  </label>
                </div>
              </div>
              <div className="add_new_details_mian">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <div className="add_new_d_inner">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group add_user_info">
                            <label className="num">Select Profile</label>
                            <div className="input-container add_user_info">
                              <select
                                className="form-control ps-4"
                                {...formik.getFieldProps("profile")}
                              >
                                <option value="">Select Profile</option>
                                <option value="Self">Self</option>
                                <option value="Relatives">Relatives</option>
                              </select>
                              <img
                                src="images/FirstName.png"
                                className="input-img pass"
                                alt=""
                              />
                              {formik.touched.profile &&
                              formik.errors.profile ? (
                                <span className="error">
                                  {formik.errors.profile}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group add_user_info">
                            <label className="num">Select Gender</label>
                            <div className="input-container add_user_info">
                              <select
                                className="form-control ps-4"
                                {...formik.getFieldProps("gender")}
                              >
                                <option value="">Select Gender</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                              </select>
                              <img
                                src="images/FirstName.png"
                                className="input-img pass"
                                alt=""
                              />
                              {formik.touched.gender && formik.errors.gender ? (
                                <span className="error">
                                  {formik.errors.gender}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group add_user_info">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="First Name"
                              {...formik.getFieldProps("firstName")}
                            />
                            <img
                              src="images/FirstName.png"
                              alt="user-icon"
                              className="user-icon2"
                            />
                            {formik.touched.firstName &&
                            formik.errors.firstName ? (
                              <span className="error">
                                {formik.errors.firstName}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group add_user_info">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Middle Name"
                              {...formik.getFieldProps("middleName")}
                            />
                            <img
                              src="images/FirstName.png"
                              alt="user-icon"
                              className="user-icon2"
                            />
                            {formik.touched.middleName &&
                            formik.errors.middleName ? (
                              <span className="error">
                                {formik.errors.middleName}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group add_user_info">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Last Name"
                              {...formik.getFieldProps("lastName")}
                            />
                            <img
                              src="images/FirstName.png"
                              alt="user-icon"
                              className="user-icon2"
                            />
                            {formik.touched.lastName &&
                            formik.errors.lastName ? (
                              <span className="error">
                                {formik.errors.lastName}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group add_user_info">
                            <input
                              type="email"
                              className="form-control"
                              placeholder="Email Address"
                              {...formik.getFieldProps("email")}
                            />
                            <img
                              src="images/email.png"
                              alt="user-icon"
                              className="user-icon2 t-email"
                            />
                            {formik.touched.email && formik.errors.email ? (
                              <span className="error">
                                {formik.errors.email}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <span className="num">Mobile Number</span>
                          <div className="form-group add_user_info">
                            <PhoneInput
                              name="mobile"
                              className="form-control"
                              placeholder="Mobile Number"
                              international
                              defaultCountry="IN"
                              countryCallingCodeEditable={false}
                              initialValueFormat="national"
                              value={formik.values.mobileNumber || ""}
                              required
                              onBlur={validateMobile}
                              onChange={(value) =>
                                formik.setFieldValue("mobileNumber", value)
                              }
                            />
                            <img
                              src="images/Mobile-Number.png"
                              alt="user-icon"
                              className="user-icon2"
                            />
                            {formik.touched.mobileNumber &&
                            formik.errors.mobileNumber ? (
                              <span className="error">
                                {formik.errors.mobileNumber}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <span className="num">Whatsapp Number</span>
                          <div className="form-group add_user_info">
                            <PhoneInput
                              name="mobile"
                              className="form-control"
                              placeholder="Whatsapp Number"
                              international
                              defaultCountry="IN"
                              countryCallingCodeEditable={false}
                              initialValueFormat="national"
                              value={formik.values.whatsappNumber || ""}
                              required
                              onBlur={validateMobile}
                              onChange={(value) =>
                                formik.setFieldValue("whatsappNumber", value)
                              }
                            />
                            <img
                              src="images/Mobile-Number.png"
                              alt="user-icon"
                              className="user-icon2"
                            />
                            {formik.touched.whatsappNumber &&
                            formik.errors.whatsappNumber ? (
                              <span className="error">
                                {formik.errors.whatsappNumber}
                              </span>
                            ) : null}
                          </div>
                        </div>

                        {!userID ? (
                          <Fragment>
                            <div className="col-md-6">
                              <div className="form-group add_user_info">
                                <input
                                  type={showPwd ? "text" : "password"}
                                  className="form-control"
                                  placeholder="Password"
                                  {...formik.getFieldProps("password")}
                                />
                                <img
                                  src="images/password.png"
                                  alt="user-icon"
                                  className="user-icon2"
                                />
                                <img
                                  src="images/view.png"
                                  alt="pwd"
                                  className="view_icon"
                                  onClick={() => setShowPwd(!showPwd)}
                                />
                                {formik.touched.password &&
                                formik.errors.password ? (
                                  <span className="error">
                                    {formik.errors.password}
                                  </span>
                                ) : (
                                  <small className="new-password-condition">
                                    *Password Must Contain 8 Characters, One
                                    Uppercase, One Lowercase, One Number and One
                                    Special Case Character
                                  </small>
                                )}
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group add_user_info">
                                <input
                                  type={showPwd ? "text" : "password"}
                                  className="form-control"
                                  placeholder="Confirm Password"
                                  {...formik.getFieldProps("confirmPassword")}
                                />
                                <img
                                  src="images/password.png"
                                  alt="user-icon"
                                  className="user-icon2"
                                />
                                <img
                                  src="images/view.png"
                                  alt="pwd"
                                  className="view_icon"
                                  onClick={() => setShowConfPwd(!showConfPwd)}
                                />
                                {formik.touched.confirmPassword &&
                                  formik.errors.confirmPassword && (
                                    <span className="error">
                                      {formik.errors.confirmPassword}
                                    </span>
                                  )}
                              </div>
                            </div>
                          </Fragment>
                        ) : null}
                        <div className="col-md-6 ">
                          <span className="num">D.O.B</span>
                          <div className="form-group add_user_info">
                            <input
                              type="date"
                              className="form-control ps-4"
                              placeholder="yyyy-mm-dd"
                              {...formik.getFieldProps("dob")}
                              title="Date of Birth"
                              max={new Date(maxAge).toISOString().split("T")[0]}
                            />
                            <img
                              src="images/date-of-birth.png"
                              className="input-img pass"
                              alt=""
                            />
                            {formik.touched.dob && formik.errors.dob ? (
                              <span className="error">{formik.errors.dob}</span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-6 ">
                          {/* <span className="num">Mother Tounge</span>
                            <div className="form-group add_user_info">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Mother Tounge"
                              {...formik.getFieldProps("firstName")}
                            />
                            <img
                              src="images/FirstName.png"
                              alt="user-icon"
                              className="user-icon2"
                            />
                            {formik.touched.firstName &&
                              formik.errors.firstName ? (
                              <span className="error">
                                {formik.errors.firstName}
                              </span>
                            ) : null}
                          </div> */}
                        </div>
                        {/* <div className="col-md-6">
                          <div className="form-group add_user_info">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="District"
                              {...formik.getFieldProps("district")}
                            />
                            <img
                              src="images/Address.png"
                              className="user-icon2"
                              alt=""
                            />
                            {formik.touched.district &&
                              formik.errors.district ? (
                              <span className="error">
                                {formik.errors.district}
                              </span>
                            ) : null}
                          </div>

                        </div>

                        <div className="col-md-6">
                          <div className="form-group add_user_info">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="City"
                              {...formik.getFieldProps("city")}
                            />
                            <img
                              src="images/Address.png"
                              className="user-icon2"
                              alt=""
                            />
                            {formik.touched.city && formik.errors.city ? (
                              <span className="error">{formik.errors.city}</span>
                            ) : null}
                          </div>

                        </div>


                        <div className="col-md-6">
                          <div className="form-group add_user_info">
                            <select
                              className="form-control"
                              onChange={(event) => {
                                formik.setFieldValue(
                                  "country",
                                  event.target.value
                                );
                                formik.setFieldValue("state", "");
                              }}
                              value={formik.values.country || ""}
                            >
                              <option value="" disabled>
                                Select Your Country
                              </option>
                              {countryProvinces?.length
                                ? countryProvinces.map((el) => (
                                  <option value={el.name} key={el.name}>
                                    {el.name}
                                  </option>
                                ))
                                : null}
                            </select>
                            <img
                              src="images/Address.png"
                              className="user-icon2"
                              alt=""
                            />
                            {formik.touched.country && formik.errors.country ? (
                              <span className="error">
                                {formik.errors.country}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group add_user_info">
                            <select
                              className="form-control"
                              {...formik.getFieldProps("state")}
                              disabled={!stateProvinces?.length}
                              value={formik.values.state || ""}
                            >
                              <option value="" disabled>
                                Select Your State
                              </option>
                              {stateProvinces?.length
                                ? stateProvinces.map((el) => (
                                  <option value={el.name} key={el.name}>
                                    {el.name}
                                  </option>
                                ))
                                : null}
                            </select>
                            <img
                              src="images/Address.png"
                              className="user-icon2"
                              alt=""
                            />
                            {formik.touched.state && formik.errors.state ? (
                              <span className="error">
                                {formik.errors.state}
                              </span>
                            ) : null}
                          </div>
                        </div> */}
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-md-12">
                        <h5>Parent Details</h5>
                      </div>
                      <div className="col-md-4">
                        <span className="num">Full Name</span>
                        <div className="form-group add_user_info">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Full Name"
                            {...formik.getFieldProps("parent_name")}
                          />
                          <img
                            src="images/email.png"
                            alt="user-icon"
                            className="user-icon2 t-email"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <span className="num">Mobile Number</span>
                        <div className="form-group add_user_info">
                          <PhoneInput
                            name="mobile"
                            className="form-control"
                            placeholder="Mobile Number"
                            international
                            defaultCountry="IN"
                            countryCallingCodeEditable={false}
                            initialValueFormat="national"
                            value={formik.values.parent_number || ""}
                            required
                            onBlur={validateMobile}
                            onChange={(value) =>
                              formik.setFieldValue("parent_number", value)
                            }
                          />
                          <img
                            src="images/Mobile-Number.png"
                            alt="user-icon"
                            className="user-icon2"
                          />
                        </div>
                      </div>
                      <div className="col-md-4 mt-5">
                        <span
                          className="button-edit pointer"
                          onClick={handleAddEditParentsData}
                        >
                          Update Parent Details
                        </span>
                      </div>
                    </div>

                    <div className="button_area">
                      <button
                        type="submit"
                        className="save_btn"
                        disabled={saving}
                      >
                        {saving ? (
                          <CircularProgress color="inherit" />
                        ) : pathname.includes("/edit") ? (
                          "Save Changes"
                        ) : (
                          "Save"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {status === "loading" && <SimpleBackdrop open={status === "loading"} />}
    </div>
  );
};

export default AddUser;
