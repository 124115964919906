import React, { useEffect } from "react";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { deleteUser, deleteUserBioData } from "../../../api/users";
import { getUserDetails } from "../../slice";

const HandleDeleteDocument = ({
  isOpen,
  toggle,
  userID,
  page,
  userType,
  filepath,
}) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { goBack } = useHistory();
  const limit = useSelector((state) => state?.limit);

  useEffect(() => {
    document.body.classList.toggle("modal-open", isOpen);
  }, [isOpen]);

  const handleDelete = () => {
    if (userID) {
      const payload = {
        userid: userID,
        filePath: filepath,
      };

      deleteUserBioData(payload).then((response) => {
        // dispatch(idle());

        if (!response.error_code) {
          //   if (page) {
          //     dispatch(
          //       getUsersList({ tab_type: userType, page, perpage: limit })
          //     );
          //     dispatch(getUsersCount({}));
          //   } else {
          //     goBack();
          //   }
          dispatch(getUserDetails({ user_id: userID }));

          toggle();
        }
        enqueueSnackbar(response.message);
      });
    }
  };

  return (
    <>
      <div
        className={isOpen ? "modal fade show" : "modal fade"}
        id="delUserDetails"
        style={{ display: isOpen ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Delete Document</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={toggle}
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <h4 className="del_user">
                Are you sure, you want to delete this document?
              </h4>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="Del_btn"
                data-dismiss="modal"
                onClick={handleDelete}
              >
                Delete
              </button>
              <button
                type="button"
                className="Can_btn"
                data-dismiss="modal"
                onClick={toggle}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      {isOpen && <div className="modal-backdrop fade show"></div>}
    </>
  );
};

HandleDeleteDocument.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  userID: PropTypes.string.isRequired,
  page: PropTypes.number,
  userType: PropTypes.string.isRequired,
};

export default HandleDeleteDocument;
