import {
  getData,
  postEncodedData,
  postEncodedDataDelete,
  postEncodedDataPatch,
} from ".";

export async function addUpdateOffer(params) {
  return postEncodedData("/admin/add_update_offer", params).then((data) => {
    return data;
  });
}
export async function addNotification(params) {
  return postEncodedData("/admin/user/save_admin_notification", params).then(
    (data) => {
      return data;
    }
  );
}
export async function addUpdateOccupation(params) {
  return postEncodedData("/admin/add_update_occupation", params).then(
    (data) => {
      return data;
    }
  );
}
export async function addUpdateCourses(params) {
  return postEncodedData("/admin/add_update_course", params).then((data) => {
    return data;
  });
}

export async function addUpdateGothra(params) {
  return postEncodedData("/admin/add_update_gothra", params).then((data) => {
    return data;
  });
}
export async function addUpdateDevak(params) {
  return postEncodedData("/admin/add_update_devak", params).then((data) => {
    return data;
  });
}

export async function addUpdateSpecialization(params) {
  return postEncodedData("/admin/add_update_specialization", params).then(
    (data) => {
      return data;
    }
  );
}
export async function fetchOffer(params) {
  return getData(`/admin/get_offer?perpage&page=${params.page}`, params).then(
    (data) => {
      return data;
    }
  );
}
export async function fetchOccupation(params) {
  return getData(
    `/admin/get_occupation?perpage=${params.perpage}&page=${params.page}`,
    params
  ).then((data) => {
    return data;
  });
}
export async function fetchCourse(params) {
  return getData(
    `/admin/get_course?perpage&page=${params.page}&search`,
    params
  ).then((data) => {
    return data;
  });
}
export async function fetchPhotos(params) {
  return getData(
    `/admin/user/get_all_images?page=${params.page}&perpage=${10}`,
    params
  ).then((data) => {
    return data;
  });
}
export async function fetchNotifications(params) {
  return getData(
    `/user/notification_list?page=${params.page}&limit=${10}`,
    params
  ).then((data) => {
    return data;
  });
}
export async function fetchUnreadNotifications(params) {
  return getData(`/user/get_unseen_notification_total`, params).then((data) => {
    return data;
  });
}
export async function fetchSpecialization(params) {
  return getData(
    `/admin/get_specialization?perpage&page=${params.page}&search`,
    params
  ).then((data) => {
    return data;
  });
}
export async function fetchNotification(params) {
  return getData(
    `/admin/user/admin_notification_list?search=&perpage=10&page=1`,
    params
  ).then((data) => {
    return data;
  });
}
export async function fetchGothra(params) {
  return getData(
    `/admin/get_gothra?perpage&page=${params.page}&search`,
    params
  ).then((data) => {
    return data;
  });
}
export async function fetchDevak(params) {
  return getData(
    `/admin/get_devak?perpage&page=${params.page}&search`,
    params
  ).then((data) => {
    return data;
  });
}
export async function fetchOfferDetails(params) {
  return getData(`/admin/get_offer_details/${params?.id}`).then((data) => {
    return data;
  });
}
export async function fetchFaqDetails(params) {
  return getData(`/get_faqs_details/${params?.id}`).then((data) => {
    return data;
  });
}
export async function fetchCourseDetails(params) {
  return getData(`/admin/get_course_details/${params?.id}`).then((data) => {
    return data;
  });
}
export async function fetchSpecializationDetails(params) {
  return getData(
    `/admin/get_specialization_details/${params?.id}`,
    params
  ).then((data) => {
    return data;
  });
}
export async function fetchCourseList(params) {
  console.log("Params course", params?.higher_education);
  return getData(`/get_course_list`, params).then((data) => {
    return data;
  });
}
export async function fetchSpecializationList(params) {
  return getData(`/get_specialization_list?course=${params?.id}`, params).then(
    (data) => {
      return data;
    }
  );
}
export async function fetchEmploymentList(params) {
  return getData(`/get_occupation_list`, params).then((data) => {
    return data;
  });
}
export async function fetchCommunityList(params) {
  return getData(`/get_community_list?religion_id=${params?.id}`, params).then(
    (data) => {
      return data;
    }
  );
}
export async function fetchSubcasteList(params) {
  return getData(`/get_subcast_list?community_id=${params?.id}`, params).then(
    (data) => {
      return data;
    }
  );
}
export async function fetchGothraList(params) {
  return getData(
    `/get_ghothra_list?community_id=6463660e5db3dc8d734810d9&sub_caste=64772664bfe2d43126cc1cd3`,
    params
  ).then((data) => {
    return data;
  });
}
export async function fetchDevakList(params) {
  return getData(
    `/get_devak_list?community_id=6463660e5db3dc8d734810d9&sub_caste=64772664bfe2d43126cc1cd3&gothra=${params?.id}`,
    params
  ).then((data) => {
    return data;
  });
}
export async function fetchNotificationDetails(params) {
  return getData(`/admin/user/admin_notification_details/${params?.id}`).then(
    (data) => {
      return data;
    }
  );
}
export async function deleteOffer(params) {
  return postEncodedDataDelete("/admin/remove_offer", params).then((data) => {
    return data;
  });
}
export async function deleteOccupation(params) {
  return postEncodedDataDelete("/admin/remove_occupation", params).then(
    (data) => {
      return data;
    }
  );
}
export async function deleteNotification(params) {
  return postEncodedDataDelete(
    `/admin/user/remove_admin_notification/${params.id}`,
    params
  ).then((data) => {
    return data;
  });
}
export async function deleteCourse(params) {
  return postEncodedDataDelete("/admin/remove_course", params).then((data) => {
    return data;
  });
}
export async function deleteSpecialization(params) {
  return postEncodedDataDelete("/admin/remove_specialization", params).then(
    (data) => {
      return data;
    }
  );
}
export async function deleteGothra(params) {
  return postEncodedDataDelete("/admin/remove_gothra", params).then((data) => {
    return data;
  });
}
export async function deleteDevak(params) {
  return postEncodedDataDelete("/admin/remove_devak", params).then((data) => {
    return data;
  });
}
export async function updateStatusOffer(params) {
  return postEncodedDataPatch("/admin/update_offer_status", params).then(
    (data) => {
      return data;
    }
  );
}
