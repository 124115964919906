import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

const HighestEducation = ({
  setCourseValue,
  checkedItems,
  setCheckedItems,
}) => {
  const dispatch = useDispatch();
  const highereducation = useSelector((state) => state?.higherEducation);

  const handleCheckOptions = (event) => {
    const { value, checked } = event.target;

    if (checked) {
      setCheckedItems([...checkedItems, value]); // add the value to the array
      setCourseValue([...checkedItems, value]);
    } else {
      setCourseValue(checkedItems.filter((item) => item !== value)); // remove the value from the array
    }
  };

  return (
    <div className="gov text-center mb-4 mt-5">
      <h4>Highest Education</h4>
      {highereducation?.map((res, i) => {
        return (
          <div className="" key={i}>
            <input
              type="checkbox"
              id="notifyAllUsers"
              name="notifyAllUsers"
              value={res?._id}
              // checked={checkedItems.includes(res?.id)}
              onChange={handleCheckOptions}
            />
            <label htmlFor="groupA" className="mx-3">
              {res?.name}
            </label>
          </div>
        );
      })}
    </div>
  );
};

export default HighestEducation;
