import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";

import { changeuserPageNumber, getUsersList, usersList } from "../slice";
import SimpleBackdrop from "../../components/backdrop";
import { useToggle } from "../../app/hooks";
import TabReport from "./Tab";
import { getTransactionList } from "../slice";
import { getRevenueList } from "../slice";
import CsvDownloadButton from "react-json-to-csv";

const RevenueGenerated = () => {
  const dispatch = useDispatch();

  const status = useSelector((state) => state?.status);
  const limit = useSelector((state) => state?.limit);
  const data = useSelector((state) => state?.revenueList);
  const [page, setPage] = useState(1);
  const userPageNumber = useSelector((state) => state?.userPageNumber);

  useEffect(() => {
    if (userPageNumber) {
      setPage(userPageNumber);
    }
  }, [userPageNumber]);
  useEffect(() => {
    dispatch(getRevenueList({}));
  }, [dispatch]);

  useEffect(() => {
    return () => dispatch(usersList(null));
  }, [dispatch]);

  return (
    <div className="m-main-content">
      <TabReport />
      <div className="d-flex justify-content-end mx-3">
        <CsvDownloadButton
          data={data?.data?.earnings}
          filename="earning.csv"
          style={{
            //pass other props, like styles
            boxShadow: "inset 0px 1px 0px 0px #e184f3",
            background: "linear-gradient(to bottom, #EC008C 5%, #0F75BC 100%)",
            backgroundColor: "#c123de",
            borderRadius: "6px",
            border: "1px solid #a511c0",
            display: "inline-block",
            cursor: "pointer",
            color: "#ffffff",
            fontSize: "15px",
            fontWeight: "bold",
            padding: "6px 24px",
            textDecoration: "none",
            textShadow: "0px 1px 0px #9b14b3",
            marginLeft: "10px",
          }}
        >
          Export CSV ✨
        </CsvDownloadButton>
      </div>
      <div className="brand_table">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="brand_table_inner">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.data?.earnings?.length > 0 ? (
                        data.data.earnings.map((el) => (
                          <tr key={el._id}>
                            <td className="bt-non">{el?._id}</td>
                            <td className="bt-non">{el?.totalAmount}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={2} style={{ padding: "10px" }}>
                            No Results Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  {data?.pages > 1 ? (
                    <Pagination
                      count={data.pages}
                      page={page && page}
                      onChange={(e, page) => {
                        (async () => {
                          await localStorage.setItem("userPageNumber", page);
                        })();
                        dispatch(changeuserPageNumber(page));
                        setPage(page);
                      }}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SimpleBackdrop open={status === "loading"} />
      {/* <HandleDelete
                isOpen={isOpenDelete}
                toggle={toggleDelete}
                userID={userID}
                page={page}
                userType="new"
            />
            <HandleVerify
                isOpen={isOpenVerify}
                toggle={toggleVerify}
                userID={userID}
                page={page}
                userType="new"
                status={value}
            /> */}
    </div>
  );
};

export default RevenueGenerated;
