import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "react-js-pagination";
import { useToggle } from "../../app/hooks";
import PhotosModal from "./photoModal";
import ApproveRejectPhotos from "./approveRejectPhotos";
import { getPhotos } from "../slice";
import { Link } from "react-router-dom";
import SimpleBackdrop from "../../components/backdrop";

const UserPhotos = () => {
  const dispatch = useDispatch();
  const [isOpen, toggle] = useToggle(false);
  const [isOpenDelete, toggleDelete] = useToggle(false);
  const [page, setPage] = useState(1);
  const [userID, setUserID] = useState("");
  const statusLoder = useSelector((state) => state?.status);

  const [status, setStatus] = useState("");
  const [imageId, setImageId] = useState("");
  const [activePage, setActivePage] = useState(1);
  const photos = useSelector((state) => state?.photos);
  const [image, setImage] = useState();

  useEffect(() => {
    dispatch(getPhotos({ page: page, limit: 10 }));
  }, [dispatch, page]);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    dispatch(getPhotos({ page: pageNumber }));
  };

  return (
    <div className="m-main-content">
      <div className="brand_main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-7">
              <div className="brand_head_data">
                <div className="brands_total deactive_user">
                  {/* <img src="images/Deactivated-users-white.png" alt="user" /> */}
                  <p className="t_brand_txt">Total Pending Photos</p>
                  <h4 className="brand_num">{photos?.total_counts || 0}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="brand_table">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="brand_table_inner">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Matrimony Id</th>
                        <th scope="col">First Name</th>
                        <th scope="col">Last Name</th>
                        <th scope="col">Gender</th>
                        <th scope="col">Adhar</th>
                        <th scope="col">Action</th>
                        <th scope="col">Photos</th>
                      </tr>
                    </thead>
                    <tbody>
                      {photos?.data?.length ? (
                        photos.data.map((el, i) => (
                          <tr key={el._id}>
                            <td>{el?.veer_id}</td>
                            <td className="text-capitalize bt-non">
                              {el.first_name || "N/A"}
                            </td>
                            <td className="text-capitalize bt-non">
                              {el.last_name || "N/A"}
                            </td>
                            <td className="text-capitalize bt-non">
                              {el?.gender}
                            </td>
                            <td className="bt-non">
                              <div className="p_pic">
                                {el?.user_doc?.aadharcard?.includes(".pdf") ? (
                                  <a
                                    href={el?.user_doc?.aadharcard}
                                    target="_blank"
                                    alt=""
                                  >
                                    {" "}
                                    <img
                                      src="images/pdf.png"
                                      className="action-view"
                                      alt="view"
                                    />
                                  </a>
                                ) : (
                                  <img
                                    src={el?.user_doc?.aadharcard}
                                    alt="adhar"
                                    onClick={() => {
                                      toggle();
                                      setImage(el?.user_doc?.aadharcard);
                                    }}
                                  />
                                )}
                              </div>
                            </td>
                            <td>
                              {" "}
                              <Link
                                to={`/users/approved/${el._id}`}
                                title="View"
                              >
                                <img
                                  src="images/view.png"
                                  className="action-view"
                                  alt="view"
                                />
                              </Link>
                            </td>
                            <td className="bt-non">
                              {el?.profile_images?.map((res, i) => {
                                return (
                                  <div className="p_pic" key={i}>
                                    <img
                                      src={res?.image_url}
                                      alt=""
                                      onClick={() => {
                                        toggle();
                                        setImage(res?.image_url);
                                      }}
                                    />
                                    <span className="table-action mx-3">
                                      <button
                                        style={{
                                          background: "none",
                                          border: "none",
                                        }}
                                        title="Approve"
                                        onClick={() => {
                                          setStatus(1);
                                          setImageId(res?._id);
                                          setUserID(el._id);
                                          toggleDelete();
                                        }}
                                      >
                                        <img
                                          src="images/approve.png"
                                          className="action-view"
                                          alt="approve"
                                        />
                                      </button>
                                      <button
                                        style={{
                                          background: "none",
                                          border: "none",
                                        }}
                                        title="Reject"
                                        onClick={() => {
                                          toggleDelete();
                                          setUserID(el._id);
                                          setStatus(2);
                                          setImageId(res?._id);
                                        }}
                                      >
                                        <img
                                          src="images/reject.png"
                                          className="action-view"
                                          alt="reject"
                                        />
                                      </button>
                                    </span>
                                  </div>
                                );
                              })}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={7} style={{ padding: "10px" }}>
                            No Results Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <SimpleBackdrop open={statusLoder === "loading"} />

                  {photos?.total_user_counts > 0 ? (
                    <div className="mt-4 mb-4">
                      <Pagination
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={photos?.total_user_counts}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <PhotosModal isOpen={isOpen} toggle={toggle} image={image} />
      <ApproveRejectPhotos
        isOpen={isOpenDelete}
        toggle={toggleDelete}
        status={status}
        userID={userID}
        imageId={imageId}
        page={page}
      />
    </div>
  );
};

export default UserPhotos;
